<template>
  <div class="wrap">
    <div class="name flex align-items-center justify-content-space-between">
      <div>GeForce 3060 Cloud Terminal</div>
      <div>
        <span class="dot mr10" style="background: rgba(217, 217, 217, 0.1);"></span>
        <span class="dot mr10" style="background: rgba(217, 217, 217, 0.2);"></span>
        <span class="dot" style="background: rgba(217, 217, 217, 1);"></span>
      </div>
    </div>
    <ComputerInfo :demo_id="demo_id"></ComputerInfo>
    <div class="bd"></div>
    <div id="terminal"></div>
  </div>
</template>

<script>
import '@xterm/xterm/css/xterm.css'
import { Terminal } from '@xterm/xterm';
import { FitAddon } from '@xterm/addon-fit';
import axios from "axios";
import {baseURL} from "@/utils/config";
import ComputerInfo from './computer-info.vue'

export default {
  props: ['session_id', 'channel', 'demo_id'],
  data () {
    return {
      lastLogTimestamp: 0,
      timer: null,
      term: null
    }
  },
  components: {
    ComputerInfo
  },
  mounted() {
    this.init()
    this.timer = setInterval(this.tick, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    this.term = null
  },
  methods: {
    tick() {
      this.getLog()
    },
    log (v) {
      this.term.writeln(v)
    },
    init () {
      const fitAddon = new FitAddon();
      this.term = new Terminal({
        cursorBlink: true,
        fontWeight: '300',
        fontSize: 12,
        theme: {
          background: '#0F0F11'
        }
      });
      this.term.loadAddon(fitAddon);
      const container = document.getElementById('terminal')
      this.term.open(container);
      fitAddon.fit();
      this.term.onKey((e) => {
        if (e.domEvent.keyCode === 13) {
          this.term.writeln('');
        } else {
          this.term.write(e.key);
        }
      });
    },
    async getLog() {
      try {
        // const baseURL = 'http://192.168.9.169:8000'
        const {data: {code, data}} = await axios.get(baseURL + '/v6/std-log', {
          params: {
            // channel: this.channel,
            // session_id: this.session_id,
            timestamp: this.lastLogTimestamp,
            limit: 100,
            demo_id: this.demo_id,
          }
        })
        if (code !== 0) {
          console.error("请求错误， 返回值报错码不为0")
          return;
        }
        this.lastLogTimestamp = data?.last_log_timestamp || 0
        this.addLog(data?.logs || [])
      } catch (e) {
        console.error(e)
      }
    },
    addLog(list) {
      for (let i = 0; i < list.length; i ++) {
        const item = list[i]
        this.log(item)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background: #0F0F11;
  border-radius: 12px;
  overflow: hidden;
  .name {
    opacity: 0.8;
    position: absolute;
    top: 20px;
    left: 16px;
    right: 16px;
    z-index: 999;
    background: #28282D;
    border-radius: 8px;
    padding: 8px 8px 8px 10px;
    font-size: 16px;
    color: #fff;
  }
  .lll-anmi {
    position: absolute;
    top: 80px;
    left: 16px;
    right: 16px;
  }
}
.dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 10px;
}
.bd {
  position: absolute;
  top: 115px;
  left: 16px;
  right: 16px;
  border-top: 1px solid rgba(255, 255, 255, .2);
}
#terminal {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 130px;
  bottom: 20px;
  opacity: 0.8;
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #292929;
  }
  ::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #5a5a5a;
    border-radius: 8px;
  }
  .xterm-screen {
    background: #0F0F11;
  }
}


.loader {
  opacity: 0.6;
  position: relative;
  left: -24px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 20px 0 #fff, -20px 0 #fff;
  animation: flash 3s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 20px 0 #FFF2, -20px 0 #FFF;
  }
  50% {
    background-color: #FFF;
    box-shadow: 20px 0 #FFF2, -20px 0 #FFF2;
  }
  100% {
    background-color: #FFF2;
    box-shadow: 20px 0 #FFF, -20px 0 #FFF2;
  }
}
</style>
