<template>
  <div class="page-wrap">
    <div class="flex align-items-center h100p">
      <div class="flex justify-content-space-between w100p h100p">
        <div class="relative flex flex-column justify-content-center">
          <div class="flex justify-content-center">
            <div>
              <div style="font-size: 90px;"  class="font-weight-600 wow fadeInLeft" data-wow-delay="0.2s">Step&nbsp;1</div>
              <div style="font-size: 40px" class="font-weight-600 mt15 wow fadeInLeft" data-wow-delay=".2s">
                输入蝉妈妈、蝉选商品链接<br>
                或者点击右侧商品<br>
              </div>
            </div>
          </div>
          <div class="big-img-box wow fadeInUp" data-wow-delay="0s">
            <img src="https://cdn-static.chanmama.com/sub-module/static-file/5/7/7d4f792bc4" alt="">
          </div>
        </div>
        <div class="mt30 wow fadeInRight flex flex-column justify-content-center" data-wow-delay="0.1s">
          <!-- 搜索框 -->
          <div class="search-input relative">
            <input v-model.trim="goodUrl" @keydown.enter="handleSubmit2()" placeholder="输入商品链接" />
            <img
                src="https://cdn-static.chanmama.com/sub-module/static-file/9/7/4fe26e7781"
                class="search-icon"
                width="34"
                @click="handleSubmit2()"
                alt=""
            />
          </div>
          <div class="good-wrapper mt50">
            <div class="good-item cursor-pointer" v-for="(item,index) in goodList" :key="index" @click="handleSubmit(item.url, item.image)">
              <img class="mr24" :src="item.image" alt="">
              <div class="flex-1 flex-center flex-direction-column justify-content-space-between">
                <div class="ellipsis-2 good-title">{{item.title}}</div>
                <div class="w100p flex justify-content-space-between align-items-center mt15">
                  <div class="fs22 mr2 opacity5 font-weight-600">{{item.price}}</div>
                  <div class="commission">{{item.sale}}</div>
                </div>
              </div>
              <TabBox></TabBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {baseURL} from "@/utils/config";

import TabBox from './tab.vue'

export default {
  components: {
    TabBox
  },
  data () {
    return {
      goodUrl: '',
      goodList: [
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-2.png'),
          title:
              "山野妹妹手工腐竹原浆制作头层腐竹烧肉无盐无添加传统150g袋",
          price: "¥19.9",
          sale: "佣金20%",
        },
      ],
    }
  },
  methods: {
    handleSubmit2 () {
      this.$message.warning('此demo网站不支持商品链接的自动解析，此功能仅商业版支持')
    },
    async handleSubmit (url) {
      this.$emit('step', 'page3', {goodUrl: url || this.goodUrl})
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrap {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  color: #fff;

  ::-webkit-scrollbar {
    width: 0;
  }
}
.search-input {
  width: 642px;
  height: 60px;
  padding-left: 20px;
  border-radius: 100px;
  background: #1b1b1b;
  box-sizing: border-box;
  border: 6px solid #7190F8;
  overflow: hidden;
  align-items: center;
  display: flex;
  .search-icon {
    position: absolute;
    right: 20px;
    top: 12px;
    cursor: pointer;
  }
  input {
    height: 28px;
    width: 100%;
    padding: 0;
    font-size: 20px;
    color: #666666;
    background: #1b1b1b;
    border: none;
    outline: none;
  }
}
.good-wrapper {
  .good-item {
    position: relative;
    width: 620px;
    display: flex;
    color: #fff;
    border-radius: 20px;
    padding: 22px 20px;
    background: #22232D;
    .good-title {
      font-size: 22px;
      font-weight: 600;
      opacity: 0.5;
    }
    .commission {
      font-size: 14px;
      font-weight: 600;
      padding: 4px 6px;
      border-radius: 10px;
      color: #FF8B7C;
      border: 1px solid #825555;
    }
    img {
      width: 120px;
      height: 120px;
      border-radius: 16px;
    }
  }
}

.big-img-box {
  position: relative;
  margin-top: -200px;
  top: 0;
  left: -80px;
  width: 600px;
  height: 600px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
