<template>
  <div class="page-1-wrapper">
    <div>AI 智能直播</div>
    <div class="start-btn flex-center" @click="$emit('step','page2')">
      <img src="https://cdn-static.chanmama.com/sub-module/static-file/0/b/afc610af03" width="85" alt="">
      <div class="blur"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'demo-page-1',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.page-1-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 170px;
  font-weight: 600;
  color: #fff;
  .start-btn {
    width: 276px;
    height: 276px;
    border-radius: 50%;
    margin-top: 100px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient(164.22deg, #5398FF 11.02%, #FF69D5 95.21%);
    .blur {
      position: absolute;
      bottom: -10%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      filter: blur(40px);
      opacity: 0.5;
      background: linear-gradient(164.22deg, #5398FF 11.02%, #FF69D5 95.21%);
    }
    img {
      z-index: 10;
    }
  }
} 
</style>
