<template>
  <div class="wrap">
    <div id="demoCanvas" loops="1"></div>
  </div>
</template>

<script>
const SVGA = require('svgaplayerweb')

export default {
  data() {
    return {
      videoItem: null,
      player: null,
      parser: null
    }
  },
  mounted() {
    this.init()
    this.timer = setInterval(() => {
      this.play()
    }, 1000 * 45)
  },
  beforeDestroy() {
    clearInterval(this.play)
  },
  methods: {
    init () {
      this.parser = new SVGA.Parser('#demoCanvas');
      this.parser.load('https://cdn-static.chanmama.com/res/upload/2024/04/02/cicaSVG.svga', (videoItem) => {
        this.videoItem = videoItem
        // this.play()
      })
    },
    async play () {
      if (!this.videoItem) {
        return
      }
      console.log('play animate')
      this.player = new SVGA.Player('#demoCanvas');
      this.player.loops = 1
      this.player.setVideoItem(this.videoItem);
      this.player.startAnimation();
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  z-index: 9999;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  display: flex;
  align-items: end;
  justify-content: center;
  pointer-events: none;
  #demoCanvas {
    width: 100%;
    height: 100%;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
