<template>
  <div style="background: #1a1b22">
    <div class="live-page pl20 pr20">
      <div class="flex-1 flex-shrink-0 h100p flex flex-column">
        <div style="height: 20px"></div>
        <div
          class="flex-1 relative full"
          style="background: #22232d; border-radius: 12px"
        >
          <div
            style="position: absolute; left: 20px; top: 20px"
            class="font-weight-600 fs20 cfff"
          >
            直播带货文案
          </div>
          <CaptionsBox
            :channel="channel"
            :scripts="scripts"
            :session_id="session_id"
            :demo_id="demo_id"
          ></CaptionsBox>
        </div>
        <div style="height: 30px"></div>
        <div class="flex-1 relative">
          <Terminal
            :channel="channel"
            :session_id="session_id"
            :demo_id="demo_id"
          ></Terminal>
        </div>
        <div style="height: 20px"></div>
      </div>
      <div
        class="h100p flex-shrink-0"
        :style="{ width: 60 + playerWidth + 'px' }"
      >
        <div
          class="video-box relative mt20"
          ref="playerWrap"
          :style="{ width: playerWidth + 'px' }"
        >
          <div class="player-box">
            <div id="my-player" @click="onVideoClick"></div>
            <LiveGift
              v-if="demo_id == 'chanxuan'"
              :playerWidth="playerWidth"
            ></LiveGift>
          </div>

          <div
            class="top-avatar-section flex align-items-center justify-content-space-between"
          >
            <div class="main-avatar flex align-items-center">
              <div class="avatar">
                <img
                  :src="
                    demo_id == 'chanxuan'
                      ? 'https://cdn-static.chanmama.com/sub-module/cmm-selection/favicon.ico'
                      : 'https://cdn-static.chanmama.com/sub-module/static-file/0/9/83b9bbf2c3'
                  "
                  alt=""
                  :width="demo_id == 'chanxuan' ? 35 : 45"
                  :height="demo_id == 'chanxuan' ? 35 : 45"
                />
              </div>

              <div
                class="flex flex-direction-column justify-content-center"
                style="margin-left: 8px"
              >
                <div class="avatar-name ellipsis-1">
                  {{ demo_id == "chanxuan" ? "蝉选选品" : "正大法律咨询" }}
                </div>
                <span class="click-sum">1.4万本场点赞</span>
              </div>
            </div>

            <div class="online-user flex align-items-center">
              <img
                src="https://cdn-static.chanmama.com/sub-module/static-file/7/1/d6f76a318c"
                alt=""
                width="30"
                height="30"
              />
              <img
                src="https://cdn-static.chanmama.com/sub-module/static-file/b/2/2e2de6fa07"
                alt=""
                width="41"
                height="41"
              />
              <img
                src="https://cdn-static.chanmama.com/sub-module/static-file/e/9/e35f1cef27"
                alt=""
                width="41"
                height="41"
              />

              <div class="user-count flex-center">236</div>
            </div>
          </div>
          <FaqBox
            :qaData="qaData"
            :session_id="session_id"
            :channel="channel"
            :demo_id="demo_id"
          ></FaqBox>
        </div>
      </div>
      <div class="flex-1 h100p flex-shrink-0">
        <QaCorrect
          :isAdmin="isAdmin"
          :qaData="qaData"
          :session_id="session_id"
          :channel="channel"
          :demo_id="demo_id"
        ></QaCorrect>
      </div>
    </div>
  </div>
</template>

<script>
import Terminal from "../components/Terminal.vue";
import "xgplayer/dist/index.min.css";
import FlvPlugin from "xgplayer-flv";
import Player from "xgplayer";
import { baseURL, flvURL, remoteFlvURL } from "@/utils/config";
import QaCorrect from "../components/qa-correct.vue";
import CaptionsBox from "../components/captions-box.vue";
import FaqBox from "../components/faq-box.vue";
import LiveGift from "../components/live-gift.vue";
import axios from "axios";

export default {
  props: {
    scriptData: {},
    channel: {},
    isAdmin: {},
    demo_id: {},
  },
  components: {
    CaptionsBox,
    QaCorrect,
    FaqBox,
    LiveGift,
    Terminal,
  },
  data() {
    return {
      session_id: "",
      scripts: [],
      qaData: [],
      playerWidth: (window.innerHeight - 40) * 0.56,
      myPlayer: null,
    };
  },
  mounted() {
    this.scripts = this.scriptData?.script || [];
    this.session_id = this.scriptData?.session_id || "";
    this.$nextTick(() => {
      this.generatePlayer();
      this.check();
    });
    window.addEventListener("resize", this.reload);
  },
  beforeDestroy() {
    this.myPlayer?.destroy();
    this.myPlayer = null;
    window.removeEventListener("resize", this.reload);
  },
  methods: {
    reload() {
      this.playerWidth = (window.innerHeight - 40) * 0.56;
    },
    async check() {
      const isPause = () => {
        return this.myPlayer?.root?.className?.includes("pause");
      };
      const isErr = () => {
        return (
          this.myPlayer?.root?.className?.includes("isloading") ||
          !this.myPlayer?.isCanplay
        );
      };
      while (this.myPlayer) {
        await new Promise((r) => setTimeout(r, 3000));
        if (isErr()) {
          await new Promise((r) => setTimeout(r, 5000));
          if (isErr()) {
            this.myPlayer?.destroy();
            this.generatePlayer();
          }
        }
      }
    },
    onVideoClick() {
      // this.myPlayer?.play()
    },
    async generatePlayer() {
      if (FlvPlugin.isSupported()) {
        let url =
          localStorage.getItem("flvURL") ||
          (this.$route.query?.local ? flvURL : "");
        if (!url) {
          try {
            const resData = await axios.get(baseURL + "/v7/live-stream-list");
            const res = resData.data;
            const data = res?.data;

            if (this.demo_id == "chanxuan") {
              url = data[0];
            } else if (this.demo_id == "law-office-1") {
              url = data[1];
            } else if (this.demo_id == "law-office-2") {
              url = data[2];
            }
          } catch (e) {
            let urlList = {
              "law-office-1":
                "https://zhibo.chanjing.cc/chanjing-live/law-office.flv?auth_key=1723104922-0-0-a488983fd4b893392122883926a5ddf2",
              "law-office-2":
                "https://zhibo.chanjing.cc/chanjing-live/law-office.flv?auth_key=1723104922-0-0-a488983fd4b893392122883926a5ddf2",
              chanxuan:
                "https://zhibo.chanjing.cc/chanjing-live/chanxuan.flv?auth_key=1754720597-0-0-5db57fb748c58b8a0763e1698c1109d7",
            };
            url = urlList[this.demo_id];
            // url = remoteFlvURL
            console.error(e);
          }
        }

        this.myPlayer = new Player({
          id: "my-player",
          url: url,
          width: "100%",
          height: "100%",
          autoplay: true,
          controls: false,
          videoFillMode: "contain",
          closeVideoClick: true,
          closeVideoDblclick: true,
          isLive: true,
          plugins: [FlvPlugin],
        });
        this.myPlayer.on("autoplay_was_prevented", () => {
          console.log("autoplay was prevented!!");
        });
      } else {
        this.$message.warning("该浏览器暂不支持flv文件");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.live-page {
  background: #1a1b22;
  max-width: 1920px;
  margin: 0 auto;
  height: 100vh;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
.video-box {
  margin-right: 30px;
  margin-left: 30px;
  border-radius: 20px;
  height: calc(100vh - 40px);
  display: flex;
  align-items: flex-end;
  padding: 0 0 15px 15px;
  box-sizing: border-box;
  overflow: hidden;
  .player-box {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    #my-player {
    }
    .xgplayer {
      background: transparent !important;
    }
  }
}

.top-avatar-section {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;

  .main-avatar {
    width: 170px;
    height: 55px;
    border-radius: 26px;
    padding-left: 5px;
    padding-right: 24px;
    background: rgba(0, 0, 0, 0.3);

    .avatar {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
    }

    .avatar-name {
      font-size: 17px;
      font-weight: 600;
    }

    .click-sum {
      font-size: 10px;
      transform: scale(0.8);
      transform-origin: top left;
      white-space: nowrap;
    }
  }

  .online-user {
    img {
      border-radius: 50%;
      margin-right: 10px;
    }
    .user-count {
      width: 62px;
      height: 42px;
      font-size: 18px;
      border-radius: 26px;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.no-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.no-scrollbar::-webkit-scrollbar-track {
  background: #292929;
}
.no-scrollbar::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 8px;
}
.no-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
  border-radius: 8px;
}
</style>
