import { render, staticRenderFns } from "./demo-page-3.vue?vue&type=template&id=bcf3c70e&scoped=true"
import script from "./demo-page-3.vue?vue&type=script&lang=js"
export * from "./demo-page-3.vue?vue&type=script&lang=js"
import style0 from "./demo-page-3.vue?vue&type=style&index=0&id=bcf3c70e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcf3c70e",
  null
  
)

export default component.exports