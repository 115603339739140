<template>
  <div class="page flex-column">
    <div class="nav-box w100p relative flex-shrink-0">
      <div class="absolute fs24 font-weight-600" style="top: 29px;left: 34px;">Live Demo</div>
      <div class="flex-center h100p c626262">
        <div class="flex-center" :class="{cfff: [1,2,3].includes(step)}">
          <span class="nav-index flex-center">1</span>
          <span class="fs16 font-weight-600">添加商品信息</span>
        </div>
        <div class="ml100 mr100 flex-center" :class="{cfff: [4,5].includes(step)}">
          <span class="nav-index flex-center">2</span>
          <span class="fs16 font-weight-600">生成带货文案</span>
        </div>
        <div class="flex-center" :class="{cfff: [6,7].includes(step)}">
          <span class="nav-index flex-center">3</span>
          <span class="fs16 font-weight-600">开始直播推流</span>
        </div>
      </div>
    </div>
    <div class="content-box flex-1">
      <!-- 添加商品信息 -->
      <div v-if="[1,2,3].includes(step)">
        <div class="pt30 pb30" style="border-bottom: 1px solid #5a5a5a;">
          <div class="font-weight-600 fs32">添加商品信息</div>
        </div>
        <div v-if="step === 1" class="flex-column align-items-center" >
          <el-input placeholder="输入抖音商品链接" v-model="formData.url" class="add-goods-input"></el-input>
          <el-button class="next-btn" @click="searchProductUrl">下一步</el-button>
        </div>
        <div v-if="step === 2" class="flex-column align-items-center" style="margin-top: 200px;">
          <MyLoading />
          <div class="font-weight-600 fs20 c999 mt60">正在解析商品链接...</div>
        </div>
        <div v-if="step === 3">
          <div class="overflow-y-auto w100p no-scrollbar" style="height: calc(100vh - 296px)">
            <div class="pt20 m-auto" style="width: 600px">
              <el-form ref="form" :model="formData" label-width="100px" size="small">
                <el-form-item label="商品链接">
                  <div class="flex items-align-center">
                    <el-input v-model="formData.url" placeholder="请输入抖音商品链接">
                      <template slot="append">
                        <el-button class="goods-confirm-btn" @click="searchProductUrl">确认</el-button>
                      </template>
                    </el-input>
                  </div>
                </el-form-item>
                <el-form-item label="商品名称">
                  <el-input v-model="formData.name" placeholder="请输入商品名称"></el-input>
                </el-form-item>
                <el-form-item label="商品描述">
                  <el-input v-model="formData.desc" type="textarea" :autosize="{ minRows: 1, maxRows: 4 }" placeholder="请输入商品描述"></el-input>
                </el-form-item>
                <el-form-item label="卖点信息">
                  <el-input v-model="formData.sell_point" type="textarea" :autosize="{ minRows: 1, maxRows: 4 }" placeholder="请输入商品卖点信息"></el-input>
                </el-form-item>
                <div class="w100p mt50 flex">
                  <el-form-item class="flex-1" label="原始价格">
                    <el-input v-model="formData.original_price" placeholder="请输入商品原始价格"></el-input>
                  </el-form-item>
                  <el-form-item class="flex-1 ml60" label="当前价格">
                    <el-input v-model="formData.current_price" placeholder="请输入商品当前价格"></el-input>
                  </el-form-item>
                </div>
                <div class="w100p flex">
                  <el-form-item class="flex-1" label="折扣信息">
                    <el-input v-model="formData.special_offers" placeholder="请输入商品折扣信息"></el-input>
                  </el-form-item>
                  <el-form-item class="flex-1 ml60" label="发货地">
                    <el-input v-model="formData.shipments_province" placeholder="请输入商品折扣信息"></el-input>
                  </el-form-item>
                </div>
                <div class="w100p flex">
                  <el-form-item class="flex-1" label="小店名称">
                    <el-input v-model="formData.shop_name" placeholder="请输入商品折扣信息"></el-input>
                  </el-form-item>
                  <el-form-item class="flex-1 ml60" label="品牌名称">
                    <el-input v-model="formData.brand_name" placeholder="请输入商品折扣信息"></el-input>
                  </el-form-item>
                </div>
                <div class="w100p mt35 flex">
                  <el-form-item class="flex-1" label="主播名称">
                    <el-input v-model="formData.anchor_name" placeholder="请输入主播名称"></el-input>
                  </el-form-item>
                  <el-form-item class="flex-1 ml60" label="主播风格">
                    <el-select v-model="formData.selling_style" placeholder="请输入带货风格" >
                      <el-option value="通用风格" label="通用风格"></el-option>
                      <el-option value="李佳琦" label="李佳琦"></el-option>
                      <el-option value="杨雨盈" label="杨雨盈"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="w100p flex">
                  <el-form-item class="flex-1" label="文案段数">
                    <el-input v-model="formData.num" min="1" max="5" type="number" placeholder="请输入带货文案段数"></el-input>
                  </el-form-item>
                  <el-form-item class="flex-1 ml60" label="每段字数">
                    <el-select v-model="formData.words_limit" placeholder="请选择字数限制" >
                      <el-option :value="1" label="500字以内"></el-option>
                      <el-option :value="2" label="500-1000字"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
          <div class="float_btn_box flex-center" >
            <el-button class="float_btn" @click="createSession">下一步</el-button>
          </div>
        </div>
      </div>
      <!-- 生成带货文案 -->
      <div v-if="[4,5].includes(step)">
        <div class="pt30 pb30 flex-between align-items-center" style="border-bottom: 1px solid #5a5a5a;">
          <div class="font-weight-600 fs32">生成带货文案</div>
          <div v-if="step === 5" class="language-box">
            <div class="language-item" :class="{'lang-select-bg': isCn}" @click="translateLang(true)">中文</div>
            <div class="language-item en-btn" :class="{'lang-select-bg': !isCn}" @click="translateLang(false)">En</div>
          </div>
        </div>
        <div v-if="step === 4" class="flex-column align-items-center" style="margin-top: 200px;">
          <MyLoading />
          <div class="font-weight-600 fs20 c999 mt60">正在生成带货文案...</div>
        </div>
        <div class="flex" v-if="step === 5">
          <div class="flex-1 relative">
            <div class="overflow-y-auto no-scrollbar" style="height: calc(100vh - 197px)">
              <div class="pt5 pb100 pr15">
                <div class="mt15" v-for="(item, index) in copywritingList" :key="index">
                  <div class="flex">
                    <div class="c999 font-weight-600 fs14" style="width: 56px">{{item.title}}</div>
                    <div class="cfff fs14 flex-1 ml38">{{item.value}}</div>
                  </div>
                  <div v-if="item.isBreak" class="pt20 pb5 w100p">
                    <div style="height: 1px;background: #5a5a5a;"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video_btn_box flex-center">
              <el-button class="video_btn" @click="step = 3">上一步</el-button>
            </div>
          </div>
          <div class="flex-1 ml20 relative">
            <div class="overflow-y-auto no-scrollbar" style="height: calc(100vh - 197px)">
              <div v-if="isTranslateLoading" class="flex-column align-items-center justify-content-center w100p h100p">
                <MyLoading />
                <div class="font-weight-600 fs20 c999 mt60">正在翻译文案...</div>
              </div>
              <div v-else class="pt30 pr20 pb100">
                <div class="mb20" v-for="(v, i) in useScriptList" :key="i">
                  <MyTextarea ref="MyTextarea" :text="v.text" :step="step" :currentIndex="i"></MyTextarea>
                </div>
              </div>
            </div>
            <div class="video_btn_box flex-center">
              <el-button class="video_btn" @click="createVideo">开启直播</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 开始直播推流 -->
      <div v-if="[6,7].includes(step)">
        <div v-if="step === 6" class="flex-1 ml20 flex-column align-items-center w100p" style="margin-top: 200px;">
          <MyLoading />
          <div class="font-weight-600 fs20 c999 mt60">正在生成直播推流...</div>
          <el-progress :percentage="videoPercent" :show-text="false" color="#ff602d" define-back-color="#464646" class="mt15" style="width: 243px"></el-progress>
        </div>
        <div v-if="step === 7" class="flex">
          <div class="flex-1 relative" style="flex-shrink: 0;">
            <div class="ml20 overflow-y-auto no-scrollbar" style="height: calc(100vh - 90px)">
              <div class="pt30 pr20 pb100 relative">
                <div class="mb20 new-script-box" v-for="(v, i) in showScriptParagraph" :key="i">
                  <div class="title_box">
                    <span class="cfff font-weight-600 fs16">文案{{ i + 1 }}:</span>
                  </div>
                  <div>
                    <div class="mb10" :class="{ active: curKey === t.key }" v-for="(t, j) in v" :key="j">{{ t.text }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="video_btn_box flex-center">
              <el-button class="video_btn" @click="step = 5">上一步</el-button>
            </div>
          </div>
          <div class="flex-1 flex-column align-items-center justify-content-center relative" style="padding-bottom: 120px">
            <div class="video-box mt30 relative" ref="playerWrap" :style="'width: ' + playerWidth + 'px'">
              <div class="player-box">
                <div id="my-player"></div>
              </div>
              <div ref="scrollContainer" class="faq-box">
                <transition-group tag="div" name="fade">
                  <div class="faq-item" v-for="(v, i) in faqList" :key="v.id">
                    {{ v.t == 'q' ? '游客: ' : '小蝉蝉: ' }}
                    {{ v.text }}
                  </div>
                </transition-group>
              </div>
            </div>
            <div class="flex items-align-center question-btn-box">
              <el-input v-model="inputQa" @keyup.enter.native="question" placeholder="输入互动信息"></el-input>
              <el-button class="question-btn" @click="question">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyTextarea from '../components/MyTextarea.vue'
import MyLoading from '../components/MyLoading.vue'
import axios from 'axios'
import { baseURL, flvURL } from '../utils/config'
import Player, { Events } from 'xgplayer';
import FlvPlugin from 'xgplayer-flv'
import 'xgplayer/dist/index.min.css';
import player from 'xgplayer/es/player'
import jsonp from 'jsonp'
import MD5 from '@/utils/md5.js'

export default {
  name: 'PageO',
  components: { MyTextarea, MyLoading },
  data () {
    return {
      baseURL: baseURL,
      step: 1,
      destroied: false, // 记录组件是否销毁
      initVideoList: [],
      formData: {
        url: "https://v.douyin.com/iLfXtFAp/ ", //url链接https://v.douyin.com/i8umYCJA/ i8gAtYpq
        name: "",  // 商品名称
        desc: "",  // 描述
        sell_point: "", //卖点信息
        original_price: "",  // 原价格
        current_price: "",  // 当前价格
        special_offers: "",  // 折扣信息
        shop_name: "",  // 小店名称
        brand_name: "",  // 品牌名称
        shipments_province: "",  // 发货地
        selling_style: "通用风格",
        num: 2,
        words_limit: 1,
        anchor_name: '小蝉蝉'
      },
      curKey: 2,
      newScripts: [],
      scriptList: [],
      enScriptList: [],
      session_id: null,
      videoPercent: 0,
      inputQa: '',
      cursorId: 0,
      playerWidth: 408,
      faqList: [ { id: this.getId(), t: 'a', text: '欢迎' } ],
      isCn: true,
      isTranslateLoading: false
    }
  },
  computed: {
    copywritingList(){
      const list = [
        {title: '商品名称', value: this.formData.name, isBreak: false},
        {title: '商品描述', value: this.formData.desc, isBreak: false},
        {title: '卖点信息', value: this.formData.sell_point, isBreak: true},
        {title: '原始价格', value: this.formData.original_price, isBreak: false},
        {title: '当前价格', value: this.formData.current_price, isBreak: false},
        {title: '折扣信息', value: this.formData.special_offers, isBreak: false},
        {title: '发货地', value: this.formData.shipments_province, isBreak: false},
        {title: '小店名称', value: this.formData.shop_name, isBreak: false},
        {title: '品牌名称', value: this.formData.brand_name, isBreak: true},
        {title: '主播名称', value: this.formData.anchor_name, isBreak: false},
        {title: '主播风格', value: this.formData.selling_style, isBreak: false},
        {title: '文案段数', value: this.formData.num, isBreak: false},
        {title: '每段字数', value: this.formData.words_limit, isBreak: false}
      ]
      return list ?? []
    },
    useScriptList(){
      return this.isCn ? this.scriptList : this.enScriptList
    },
    showScriptParagraph() {
      const paragraph = []
      this.newScripts.forEach(v => {
        const i = v.text_index || 0
        if (!paragraph[i]) {
          paragraph[i] = []
        }
        paragraph[i].push(v)
      })
      return paragraph
    }
  },
  mounted(){
    // const tempHeight = this.$refs.playerWrap.offsetHeight
    // this.playerWidth = tempHeight * 0.56
    // this.generatePlayer()
  },
  beforeDestroy() {
    this.destroied = true
  },
  methods: {
    async searchProductUrl() {
      if(!this.formData.url){
        this.$message.warning("请输抖音商品链接！")
        return
      }
      this.step = 2
      try {
        const {data: {data, code, msg}} = await axios.post(baseURL + '/v3/get_url_info', {
          url: this.formData.url
        })
        if (code !== 0) {
          this.$message.warning(msg)
          return
        }
        const info = JSON.parse(data.information)
        let tempDesc = ''
        for (let [key, value] of Object.entries(info.desc.product_format)){
          tempDesc += `${key}：${value}；`
        }
        tempDesc += info.desc.ocr.join('、')
        let tempSellPoint = ''
        for (let [key, value] of Object.entries(info.sell_point)){
          tempSellPoint += `${key}：${value.join('、')}；`
        }
        this.formData = {
          ...this.formData,
          ...info,
          desc: tempDesc,
          sell_point: tempSellPoint
        }
        this.step = 3
      } catch (e) {
        console.error(e)
      } finally {}
    },
    async createSession() {
      const judgeList = [
        {condition: this.formData.name, msg: '商品名称不可为空'},
        {condition: this.formData.current_price, msg: '当前价格不可为空'},
        {condition: parseInt(this.formData.num) > 0, msg: '文案数量必须要大于0'}
      ]
      const result = judgeList.find(item => !item.condition)
      if(result){
        this.$message.warning(result.msg)
        return
      }
      this.step = 4
      let req = {
        ...this.formData,
        "num": parseInt(this.formData.num)
      };
      try {
        const resData = await axios.post(baseURL + '/v2/session', req)
        const res = resData.data
        if (res.code !== 0) {
          this.$message.warning(res.msg)
          this.step = 3
          return;
        }
        this.session_id = res.data.session_id;
        this.scriptList = res.data.scripts?.map(v => {
          return {
            text: v
          }
        })
        this.step = 5
      } catch (e) {
        console.error(e)
      } finally {}
    },
    async translateLang(isCn) {
      this.isCn = isCn
      if(isCn && this.scriptList.length || !isCn && this.enScriptList.length){
        return
      }
      this.isTranslateLoading = true
      const appid = '20240119001945558'
      const key = 'qckPS6EsvDKss1mmoft2'
      const salt = (new Date).getTime()
      const promises = this.scriptList.map(item => {
        const query = item.text.replace(/<span style="color:red;">|<\/span>/g, '')
        const str = appid + query + salt + key
        const params = {
          q: query,
          appid,
          salt,
          from: 'zh',
          to: 'en',
          sign: MD5(str)
        }
        return this.requestLang(params)
      })
      Promise.all(promises).then(results => {
        this.enScriptList = results.map(item => {
          let str = ''
          item.trans_result.forEach(val => {
            str = !str ? val.dst : str + '\n\n' + val.dst
          })
          return {text: str}
        })
        this.isTranslateLoading = false
      })
    },
    async requestLang(params) {
      const url = 'http://api.fanyi.baidu.com/api/trans/vip/translate'
      const queryString = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')
      return new Promise((resolve, reject) => {
        jsonp(`${url}?${queryString}&callback=jpback`, null, (err, data) => {
          resolve(data)
        })
      })
    },
    async createVideo() {
      if (!this.session_id) {
        this.$message.warning('请先生成带货脚本')
        return
      }
      this.videoPercent = 0
      this.step = 6
      let text = ''
      if(this.isCn){
        this.scriptList.forEach((v, i) => {
          const tempStr = this.$refs.MyTextarea[i]?.getText()
          v.text = tempStr.replace(/\n{3,}/g, "\n\n")
        })
        text = this.scriptList.map(v => {
          return v.text
        })
      }else{
        this.enScriptList.forEach((v, i) => {
          const tempStr = this.$refs.MyTextarea[i]?.getText()
          v.text = tempStr.replace(/\n{3,}/g, "\n\n")
        })
        text = this.enScriptList.map(v => {
          return v.text
        })
      }
      const req = {
        session_id: this.session_id,
        lang: this.isCn ? 'cn' : 'en',
        text
      }
      try {
        const resData = await axios.post(baseURL + '/v3/create_wav', req)
        const res = resData.data
        if (res.code !== 0) {
          this.$message.warning(res.msg)
          this.step = 5
          return;
        }
        this.newScripts = res.data
        await this.getVideoProgress()
      } catch (e) {
        console.error(e)
      } finally { }
    },
    async getVideoProgress() {
      while (!this.destroied) {
        await new Promise(r => setTimeout(r, 3000))
        try {
          const resData = await axios.get(baseURL + '/v2/create_video?session_id='+this.session_id)
          const res = resData.data
          const status = res.data.status
          if (res.code !== 0) {
            this.$message.warning(res.msg)
            break;
          }
          if (status === 4) {
            this.$message.warning('生成失败')
            break
          }
          this.videoPercent = res.data.percent === 100 ? this.videoPercent : res.data.percent
          if (status === 3 || res.data.video_list?.length || res.data.percent === 100) {
            this.initVideoList = res.data.video_list || []
            this.step = 7
            this.$nextTick(() => {
              const tempHeight = this.$refs.playerWrap.offsetHeight
              this.playerWidth = tempHeight * 0.56
              this.generatePlayer()
            })
            break
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    generatePlayer() {
      if (FlvPlugin.isSupported()) {
        const myPlayer = new Player({
          id: 'my-player',
          url: flvURL,
          width: '100%',
          height: '100%',
          autoplay: true,
          controls: false,
          videoFillMode: 'contain',
          closeVideoClick: true,
          closeVideoDblclick: true,
          isLive: true,
          plugins: [FlvPlugin],
        })
      } else {
        this.$message.warning("该浏览器暂不支持flv文件")
      }
    },
    async question() {
      if (this.inputQa.trim().length === 0) {
        return
      }
      this.faqList.push({
        id: this.getId(),
        t: 'q',
        text: this.inputQa.trim()
      })
      this.goTalkBottom()
      const tempQ = this.inputQa
      this.inputQa = ''
      const {data: {code, data}} = await axios.get(baseURL + '/v3/session/faq_wav', {
        params: {
          session_id: this.session_id,
          question: tempQ
        }
      })
      if (code !== 0) {
        this.$message.warning("请求错误， 返回值报错码不为0")
        return;
      }
      this.faqList.push({
        id: this.getId(),
        t: 'a',
        text: data.answer
      })
      this.goTalkBottom()
    },
    getId() {
      return this.cursorId++
    },
    goTalkBottom(){
      this.$nextTick(() => {
        let container = this.$refs.scrollContainer;
        container.scrollTop = container.scrollHeight;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;
  max-height: 100vh;
  background: #292929;
  color: #fff;
  .nav-box{
    height: 90px;
    box-sizing: border-box;
    border-bottom: 1px solid #5a5a5a;
    .nav-index{
      width: 18px;
      height: 18px;
      background: #000;
      border-radius: 50%;
      margin-right: 9px;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .content-box{
    width: 900px;
    margin: 0 auto;
    position: relative;
    .goods-confirm-btn{
      background: #FF602D;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      border: none;
      width: 70px;
    }
    .float_btn_box {
      height: 100px;
      background: #292929;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      .float_btn{
        width: 400px;
        height: 46px;
        background: #ff602d;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        border: none;
        border-radius: 8px;
      }
    }
    .video_btn_box{
      width: 410px;
      height: 100px;
      background: linear-gradient(to bottom, rgba(41,41,41,0) 0, rgba(41,41,41,1) 70%);
      position: absolute;
      bottom: 0;
      .video_btn{
        width: 370px;
        height: 46px;
        background: #ff602d;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        border: none;
        border-radius: 8px;
      }
    }
    .next-btn{
      margin-top: 40px;
      border-radius: 8px;
      border: none;
      background: #FF602D;
      width: 292px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
    }
    .language-box{
      width: 120px;
      height: 30px;
      box-sizing: border-box;
      border: 1px solid #7a7a7a;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      color: #fff;
      display: flex;
      align-items: center;
      overflow: hidden;
      .language-item {
        flex: 1;
        text-align: center;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
      }
      .en-btn{
        border-left: 1px solid #7a7a7a;
      }
      .lang-select-bg {
        background: #ff602d;
      }
    }
    .video-box{
      border-radius: 8px;
      height: calc(100vh - 240px);
      max-width: 408px;
      max-height: 728px;
      display: flex;
      align-items: flex-end;
      padding: 0 0 15px 15px;
      box-sizing: border-box;
      overflow: hidden;
      .player-box{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      .faq-box {
        max-width: 68%;
        max-height: 36%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 12px;
        padding: 5px 10px;
        box-sizing: border-box;
        z-index: 10;
        .faq-item {
          text-align: left;
          line-height: 20px;
          word-wrap: break-word;
          word-break: break-all;
          font-size: 12px;
          color: #FFF;
        }
      }
      .faq-box::-webkit-scrollbar {
        display: none;
      }
    }
    .question-btn-box{
      width: 408px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-radius: 12px;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 8px;
      box-sizing: border-box;
      .question-btn{
        width: 84px;
        height: 40px;
        border-radius: 8px;
        border: none;
        background: #ff602d;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .no-scrollbar::-webkit-scrollbar {
      width: 8px;
    }
    .no-scrollbar::-webkit-scrollbar-track {
      background: #292929;
    }
    .no-scrollbar::-webkit-scrollbar-thumb {
      background: #4a4a4a;
      border-radius: 8px;
    }
    .no-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #5a5a5a;
      border-radius: 8px;
    }
    .talk-scrollbar::-webkit-scrollbar-track {
      background: #383838;
      border-radius: 8px;
    }
  }

  &::v-deep {
    .add-goods-input{
      width: 707px;
      margin-top: 200px;
      .el-input__inner{
        border-radius: 12px;
        height: 56px;
        border: 1px solid rgba(255, 255, 255, 0.6);
      }
    }
    .question-btn-box .el-input__inner{
      border: none;
    }
    .el-form-item {
      margin-bottom: 15px;
    }
    .el-form-item__label {
      color: #999;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      padding-top: 8px;
    }
    .el-input__inner {
      height: 42px;
      background-color: #292929;
      border: 1px solid #4a4a4a;
      color: #fff;
    }
    .el-input__inner::placeholder {
      color: #666;
    }
    .el-input-group__append{
      padding-top: 3px;
      width: 33px;
      background: #292929;
      border: 1px solid #4a4a4a;
      border-left: none;
      transform: translateX(-1px);
    }
    .el-textarea__inner{
      min-height: 42px !important;
      padding: 10px 15px;
      background-color: #292929;
      border: 1px solid #4a4a4a;
      color: #fff;
    }
    .el-textarea__inner::placeholder {
      color: #666;
    }
    .el-textarea__inner::-webkit-scrollbar {
      width: 8px;
    }
    .el-textarea__inner::-webkit-scrollbar-track {
      background: #292929;
    }
    .el-textarea__inner::-webkit-scrollbar-thumb {
      background: #4a4a4a;
      border-radius: 8px;
    }
    .el-textarea__inner::-webkit-scrollbar-thumb:hover {
      background: #5a5a5a;
      border-radius: 8px;
    }
  }
  .new-script-box {
    position: relative;
    border: 1px solid #4a4a4a;
    border-radius: 6px;
    background: #2d2d2d;
    padding: 63px 20px 10px;
    font-size: 14px;
    color: #cbcbcb;
    white-space: pre-line;
    .title_box{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
    }
  }
}
</style>
