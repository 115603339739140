<template>
  <div class="wrap">
    <div ref="scriptBox" class="script-box" :style="{ transform: `translateY(${translateY}px)` }">
      <div class="text" :class="{ active: curKey === v.key, qa: v.type == 'qa' }" v-for="(v, i) in scripts" :id="'key_' + v.key" :key="v.key">
        {{ v.text }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {baseURL} from "@/utils/config";

const screenHeight = window.innerHeight
const initScreenHeight = screenHeight / 4
export default {
  props: ['session_id', 'scripts', 'channel', 'demo_id'],
  data() {
    return {
      faqList: [],
      timer: null,
      translateY: initScreenHeight,
      speechRate: 1,
      maxTranslateY: 0,
      curIndex: -1,
      curKey: '', // 当前解说的文案key
    }
  },
  watch: {
    curIndex (nv, ov) {
      if (nv < ov) {
        this.reStart()
      }
    },
    curKey () {
      this.$nextTick(() => {
        this.setClientHeight()
        this.setTranslateY()
        this.setSpeechRate()
      })
      console.log('curKey change = ', this.curKey)
      console.log('cur text =', this.scripts[this.curIndex]?.text)
    }
  },
  mounted() {
    this.setClientHeight();
    this.timer = setInterval(this.tick, 100);
    this.getKey();
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    tick() {
      if (this.curKey) {
        if (this.translateY > this.maxTranslateY) {
          this.translateY -= this.speechRate
        }
      }
    },
    async getKey() {
      while (true) {
        await new Promise(r => setTimeout(r, 1000))
        try {
          const { channel, demo_id } = this
          const url = baseURL + '/get_key'
          const resData = await axios.get(url, { params: {channel, demo_id} })
          const res = resData.data
          const data = res?.data
          console.log('get key =', data?.key, data.value)
          if (data?.key) {
            this.setKey(data.key, data.value)
          } else {
            this.speechRate = 0
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    setKey (k, text) {
      const index = this.scripts.findIndex(v => v.key == k)
      if (index == -1) {
        console.log('未在话术列表匹配key=', k)
        if (text) {
          const i = this.curIndex == -1 ? this.scripts.length : this.curIndex + 1
          this.scripts.splice(i, 0, {
            type: 'qa',
            text: text,
            key: k
          })
          console.log('新增口播，', k)
          this.setKey(k)
        } else {
          console.log('error set key, 未匹配key')
        }
      } else {
        if (this.curKey != k) {
          console.log('set key =', k)
          this.curKey = k
          this.curIndex = index
        }
      }
    },
    setTranslateY () {
      let h = 0
      let isFind = false
      let itemIndex = -1;
      for (let i = 0; i < this.scripts.length; i++) {
        if (this.scripts[i].key == this.curKey) {
          isFind = true
          itemIndex = i;
          break
        } else {
          h += (this.scripts[i].clientHeight || 0) + 20
        }
      }
      if (isFind) {
        this.translateY = -h + initScreenHeight
        this.maxTranslateY = this.translateY - ((this.scripts[itemIndex].clientHeight || 0) + 20) - (initScreenHeight * 0.5)
        console.log('translateY = ', this.translateY)
        console.log('maxTranslateY = ', this.maxTranslateY)
      } else {
        console.log('未匹配', this.curKey)
      }
    },
    setClientHeight () {
      this.scripts.forEach((v) => {
        if (!v.clientHeight) {
          v.clientHeight = document.getElementById('key_' + v.key)?.clientHeight || 0
        }
      })
    },
    setSpeechRate() {
      const fd = this.scripts.find(v => v.key === this.curKey)
      if (fd) {
        const speechRate = ((fd.clientHeight + 20) / (fd.text.length / 4)) / 10;
        console.log('speechRate = ', speechRate, '高度： ', fd.clientHeight, '， 字数，',  fd.text.length)
        if (speechRate > 0 && speechRate < 5) {
          this.speechRate = speechRate
        } else {
          this.speechRate = 1
        }
      }
    },
    async reStart () {
      console.log('字幕重新开始')
      this.$refs.scriptBox.style.opacity = '0'
      for (let i = this.scripts.length - 1; i >= 0; i--) {
        if (this.scripts[i].type == 'qa') {
          let removedElement = this.scripts.splice(i, 1)[0];
          console.log('删除的口播字幕：', removedElement);
        }
      }
      await new Promise(r => setTimeout(r, 600))
      this.$refs.scriptBox.style.opacity = '1'
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: absolute;
  top: 20px;
  left: 20px;
  bottom: 20px;
  right: 20px;
  margin: 0 10px 0 auto;
  color: rgb(255, 255, 255);
  mask-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 10%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0.9) 45%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.9) 55%, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0.05) 90%, rgba(0, 0, 0, 0) 100%);
  font-size: 18px;
  font-weight: 400;
  overflow: hidden;
  unicode-bidi: plaintext;
  .script-box {
    transition: all 0.3s;
    opacity: 1;
  }
  .text {
    margin-bottom: 20px;
    &.active {
      color: #FFFFFF;
      // color: red;
    }
    &.qa {
      color: #1EBF98;
    }
  }
}
</style>
