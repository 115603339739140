<template>
  <div class="wrap2">
    <div class="item">
      <div class="flex justify-content-space-between pl5 pr5">
        <span class="name">CPU</span>
        <span class="value">{{cpu}}%</span>
      </div>
      <div class="bar">
        <div class="progress" :style="{ width: cpu + '%' }"></div>
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="item">
      <div class="flex justify-content-space-between pl5 pr5">
        <span class="name">GPU</span>
        <span class="value">{{gpu}}%</span>
      </div>
      <div class="bar">
        <div class="progress" style="background: #C33D6D" :style="{ width: gpu + '%' }"></div>
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="item">
      <div class="flex justify-content-space-between pl5 pr5">
        <span class="name">Memory</span>
        <span class="value">{{ memory }}%</span>
      </div>
      <div class="bar">
        <div class="progress" style="background: #4F62C5" :style="{ width: memory + '%' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {baseURL} from "@/utils/config";

export default {
  props: ['demo_id'],
  data () {
    return {
      active: true,
      cpu: 0,
      gpu: 0,
      memory: 0,
    }
  },
  async mounted() {
    while (this.active) {
      await new Promise((r) => setTimeout(r, 1000))
      // this.cpu = Math.max(5, Math.min(50, this.cpu + this.randomNum()))
      // this.gpu = Math.max(10, Math.min(50, this.gpu + this.randomNum()))
      // this.memory = Math.max(20, Math.min(60, this.memory + this.randomNum()))

      this.getQa()
    }
  },
  beforeDestroy() {
    this.active = false
  },
  methods: {
    randomNum () {
      return Math.ceil(Math.random() * 4) * (Math.random() > 0.5 ? 1 : -1)
    },
    async getQa() {
      try {
        const {data: {code, data}} = await axios.get(baseURL + '/v6/performance-data', {
          params: {
            // session_id: this.session_id,
            demo_id: this.demo_id,
          }
        })
        if (code !== 0) {
          // this.$message.warning("请求错误， 返回值报错码不为0")
          console.error("请求错误， 返回值报错码不为0")
          return;
        }
        this.cpu = data.cpu_usage
        this.gpu = data.gpu_usage
        this.memory = data.memory_usage
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap2 {
  position: absolute;
  top: 70px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: space-between;
}
.name {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.5;
}
.value {
  font-size: 12px;
  font-weight: 600;
}
.item {
  flex: 2;
}
.bar {
  width: 100%;
  height: 5px;
  border-radius: 8px;
  background: #D9D9D9;
  position: relative;
  margin-top: 4px;
  .progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    background: #4FC5B7;
    border-radius: 8px;
    transition: width 0.5s;
  }
}
</style>
