<template>
  <div class="faq-box-wrap scroll-transition">
    <div ref="scrollContainer" class="faq-box scroll-transition">
      <div v-for="(v, i) in faqList" :key="v.key" class="faq-item-wrap">
        <div class="faq-item">
          <span v-if="v.typ == 'q'" style="color: #5996f2"
            >{{ v.nickname || "用户" }}:
          </span>
          <span v-else style="color: #59f2d7">主播: </span>
          <span v-if="getAite(v.text)">
            <span style="color: #5996f2">{{ getAite(v.text, 1) }}</span
            >{{ getAite(v.text) }}
          </span>
          <span v-else>{{ v.text }}</span>
        </div>
      </div>
    </div>
    <div class="flex align-items-center question-btn-box relative">
      <div class="flex-1" style="margin-right: 6px">
        <el-input
          v-model="inputQa"
          @focus="onNoTab"
          @keyup.enter.native="question"
          placeholder="输入互动信息"
        >
          <span slot="suffix" @click="question" class="cursor-pointer"
            >发送</span
          >
        </el-input>
        <TabBox v-if="showTab"></TabBox>
        <div
          class="question-icon"
          v-if="showQuestion && demo_id !== 'chanxuan'"
          @click="onNoQuestion"
        >
          <img
            src="https://cdn-static.chanmama.com/sub-module/static-file/7/f/7626b4f192"
          />
        </div>
      </div>
      <div
        class="buy-icon-container flex-center cursor-pointer"
        @click="onNoQuestion"
      >
        <img
          src="https://cdn-static.chanmama.com/sub-module/static-file/c/4/a93c77a9bc"
          alt=""
          width="30"
          height="30"
        />
      </div>
      <div class="buy-icon-container flex-center">
        <img
          src="https://cdn-static.chanmama.com/sub-module/static-file/3/d/9b40643869"
          alt=""
          width="26"
          height="26"
        />
      </div>
    </div>
    <img
      v-if="demo_id === 'chanxuan'"
      width="120"
      :class="['good-card', isCollapse && 'collapse']"
      src="https://cdn-static.chanmama.com/sub-module/static-file/6/c/9e39ffefbe"
      alt=""
    />
  </div>
</template>

<script>
import { baseURL, flvURL, remoteFlvURL } from "@/utils/config";
import axios from "axios";
import TabBox from "@/components/tab4.vue";

export default {
  props: ["channel", "session_id", "qaData", "demo_id"],
  components: {
    TabBox,
  },
  data() {
    return {
      inputQa: "",
      faqList: [],
      timer: null,
      showTab: true,
      isCollapse: true,
      showQuestion: true,
      collaspeTimer: null,
      nickname: window.localStorage.getItem("demo_nick_name"),
    };
  },
  created() {
    if (!this.nickname) {
      this.getName();
    }
  },
  mounted() {
    this.faqList.push({ t: "a", text: "欢迎", key: "welcome" });
    this.timer = setInterval(this.tick, 1000);
    this.collaspeTimer = setInterval(() => {
      this.isCollapse = !this.isCollapse;
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.collaspeTimer);
    this.timer = null;
    this.collaspeTimer = null;
  },
  methods: {
    getAite(text, type) {
      const regex = /(@[^\s+]+)(.*)/;
      const match = text.match(regex);
      console.log(match);
      if (match) {
        const name = match[1]; // 获取匹配到的名字，包括@
        const remainingText = match[2]; // 获取剩余的文字并去除两端空格
        if (type) {
          return name;
        } else {
          return remainingText;
        }
      } else {
        return false;
      }
    },
    onNoTab() {
      this.showTab = false;
    },
    onNoQuestion() {
      this.showQuestion = false;
    },
    tick() {
      this.getQa();
    },
    async getName() {
      try {
        const {
          data: { code, data },
        } = await axios.get(baseURL + "/v6/random_nickname");
        if (code !== 0) {
          // this.$message.warning("请求错误， 返回值报错码不为0")
          console.error("请求错误， 返回值报错码不为0");
          return;
        }
        this.nickname = data.nickname || "用户";
        window.localStorage.setItem("demo_nick_name", data.nickname || "");
      } catch (e) {
        console.error(e);
      }
    },
    async getQa() {
      try {
        const {
          data: { code, data },
        } = await axios.get(baseURL + "/v5/recently_qa", {
          params: {
            session_id: this.session_id,
            demo_id: this.demo_id,
          },
        });
        if (code !== 0) {
          // this.$message.warning("请求错误， 返回值报错码不为0")
          console.error("请求错误， 返回值报错码不为0");
          return;
        }
        this.addQa(data?.qas || []);
      } catch (e) {
        console.error(e);
      }
    },
    addQa(list) {
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        const fd = this.faqList.find((v) => v.key == item.key);
        if (!fd) {
          this.faqList.push(item);
          this.goTalkBottom();
        }
      }
      if (this.faqList.length > 100) {
        this.faqList.splice(0, 60);
      }
    },
    async question() {
      if (this.inputQa.trim().length === 0) {
        return;
      }
      const question = this.inputQa.trim();
      const key = Math.random().toString(36).slice(2);
      this.addQa([
        {
          typ: "q",
          key,
          text: question,
          nickname: this.nickname,
        },
      ]);
      const tempQ = this.inputQa;
      this.inputQa = "";
      const {
        data: { code, data },
      } = await axios.get(baseURL + "/v3/session/faq_wav", {
        params: {
          session_id: this.session_id,
          key,
          question: tempQ,
          nickname: this.nickname,
          demo_id: this.demo_id,
        },
      });
      if (code !== 0) {
        this.$message.warning("请求错误， 返回值报错码不为0");
        return;
      }
      this.goTalkBottom();
    },
    goTalkBottom() {
      this.$nextTick(() => {
        let container = this.$refs.scrollContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-transition {
  scroll-behavior: smooth;
  transition: scroll-behavior 0.5s ease; /* 可根据需要调整过渡时间和缓动效果 */
}

.faq-box-wrap {
  width: 100%;
  z-index: 999;
  position: relative;

  .faq-box {
    max-width: 68%;
    max-height: 220px;
    overflow-y: auto;
    z-index: 10;
    margin-bottom: 70px;

    .faq-item-wrap {
      &:nth-last-child(1) {
        .faq-item {
          opacity: 1;
        }
      }

      &:nth-last-child(2) {
        .faq-item {
          opacity: 0.9;
        }
      }

      &:nth-last-child(3) {
        .faq-item {
          opacity: 0.8;
        }
      }
    }

    .faq-item {
      display: inline-block;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 20px;
      box-sizing: border-box;
      padding: 6px 12px;
      text-align: left;
      word-wrap: break-word;
      word-break: break-all;
      font-size: 16px;
      color: #fff;
      margin-top: 8px;
      opacity: 0.5;
    }
  }

  .faq-box::-webkit-scrollbar {
    display: none;
  }

  .question-btn-box {
    padding-right: 15px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0px;
    border-radius: 30px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .question-icon {
      position: absolute;
      bottom: 51px;
      right: 32px;
      cursor: pointer;

      img {
        width: 120px;
        height: 96px;
      }
    }

    .buy-icon-container {
      width: 48px;
      height: 48px;
      margin-left: 10px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
    }

    .el-input__inner {
      border: none;
      font-size: 16px;
      padding: 20px 20px;
      border-radius: 30px;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.6);
    }

    .el-input {
      height: 48px;
    }

    .el-input__suffix {
      margin-right: 15px;
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #ff7337;
      font-weight: 600;
    }
  }

  .good-card {
    position: absolute;
    right: 14px;
    bottom: 70px;
    transition: all 0.3s ease-out;
  }
  .collapse {
    right: 30px;
    bottom: 10px;
    width: 1px;
    opacity: 0;
    transition: all 0.3s ease-out;
  }
}
</style>
