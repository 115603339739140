<template>
  <div class="wrap">
    <div class="qa-box" ref="qaBox" v-if="qaData.length">
      <div class="item" v-for="(v, i) in qaData" :key="i">
        <div class="question">{{ v.question }}</div>
        <div class="answer">
          <div class="content">
            <div :id="'edit_' + i" v-if="v.contenteditable" :contenteditable="true" v-html="v.answer"></div>
            <div v-else>{{ v.answer }}</div>
            <div style="height: 35px;">
              <div v-if="v.contenteditable" class="flex justify-content-end mt30">
                <span @click="onCancel(v, i)" class="cursor-pointer" style="color: #858585;">取消</span>
                <span @click="onConfirm(v, i)" class="cursor-pointer ml25" style="color: #5B97FE">确认</span>
              </div>
              <div v-else class="flex justify-content-end mt30">
                <span v-if="v.isCorrected" class="flex-1 fs20 c626262">已修正</span>
                <img v-if="v.showCorrect" @click="onCorrect(v, i)" class="cursor-pointer" src="../assets/error.png" width="25" height="25" alt="">
                <img @click="v.showCorrect = false" :class="{ opacity30: !v.showCorrect }" class="ml30 cursor-pointer" src="../assets/ok.png" width="29" height="25" alt="">
              </div>
            </div>
          </div>
          <div class="ai-icon ml20">AI</div>
        </div>
      </div>
    </div>
    <div style="opacity: 0.4; margin-top: 45%;" class="text-align-center">
      <img width="128" height="128" src="https://cdn-static.chanmama.com/sub-module/static-file/c/5/c9d2775128" alt="">
      <div>暂时没有互动消息</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {baseURL} from "../utils/config";

export default {
  props: ['qaData', 'session_id'],
  data () {
    return {
    }
  },
  created() {
  },
  watch: {
    qaData() {
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    }
  },
  methods: {
    onCorrect (v) {
      v.temp = v.answer
      v.contenteditable = true
    },
    onCancel(v) {
      v.answer = v.temp
      v.contenteditable = false
    },
    onConfirm(v, i) {
      const text = document.getElementById('edit_' + i)?.innerText
      if (text) {
        v.answer = text
        this.saveQa(v.question, v.answer);
      }
      v.contenteditable = false
      v.showCorrect = false
      v.isCorrected = true
    },
    scrollToBottom () {
      const element = this.$refs.qaBox;
      element.scrollTop = element.scrollHeight;
      element.style.scrollBehavior = 'smooth';
    },
    async saveQa(q, a) {
      try {
        const resData = await axios.post(baseURL + '/v4/qa/save', {
          session_id: this.session_id,
          q: q,
          a: a
        })
        const res = resData.data
        if (res.code !== 0) {
          this.$message.warning(res.msg)
        }
      } catch (e) {
        console.error(e)
      } finally { }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  background: rgba(255, 255, 255, 0.2);
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.qa-box {
  transition: scroll-behavior 0.3s ease-in-out;
  height: calc(100vh - 50px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
  .item {
    margin-top: 30px;
    padding-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    &:last-child {
      border-bottom: none;
    }
    .question {
      border-radius: 12px;
      display: inline-block;
      background: rgba(228, 228, 228, .2);
      padding: 14px 26px;
      margin-bottom: 22px;
      margin-left: 0px;
      font-size: 24px;
      position: relative;
      &::before1 {
        content: "";
        position: absolute;
        top: 10px;
        left: -15px;
        width: 25px;
        height: 25px;
        background-image: url('../assets/icon2.png'); /* 替换 'your-image-url.jpg' 为你的图片 URL */
        background-size: cover;
        background-position: center;
        z-index: 0;
        opacity: 0.4;
      }
    }
    .answer {
      display: flex;
      justify-content: right;
      position: relative;
      .content {
        font-size: 24px;
        padding: 24px 34px;
        border-radius: 12px;
        max-width: 280px;
        background: rgba(0, 0, 0, 0.8);
        position: relative;
        &::before1 {
          content: "";
          position: absolute;
          top: 20px;
          right: -18px;
          width: 25px;
          height: 25px;
          background-image: url('../assets/icon1.png'); /* 替换 'your-image-url.jpg' 为你的图片 URL */
          background-size: cover;
          background-position: center;
          z-index: 0;
          opacity: 0.4;
        }
      }
      [contenteditable]:focus {
        outline: none;
      }
    }
  }
}
.opacity30 {
  opacity: .3;
}
.ai-icon {
  flex-shrink: 0;
  background: linear-gradient(180deg, #6675FF 0%, #BD45F5 100%);
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 60px;
}
</style>
