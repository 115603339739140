<template>
  <div class="live-page ml20 mr20">
    <div class="flex-1">
      <CaptionsBox :faqList="faqList" :scripts="scripts" :session_id="session_id"></CaptionsBox>
    </div>
    <div class="video-box relative mt30" ref="playerWrap" :style="'width: calc((100vh - 60px) * 0.56)'">
      <div class="player-box">
        <div id="my-player"></div>
      </div>
      <div class="faq-box-wrap">
        <div ref="scrollContainer" class="faq-box">
          <div v-for="(v, i) in faqList" :key="v.id">
            <div class="faq-item">
              <span v-if="v.t == 'q'" style="color: #5996F2">用户: </span>
              <span v-else style="color: #59F2D7">主播: </span>
              {{ v.text }}
            </div>
          </div>
        </div>
        <div class="flex items-align-center question-btn-box">
          <el-input v-model="inputQa" @keyup.enter.native="question" placeholder="输入互动信息">
            <span slot="suffix" @click="question" class="cursor-pointer">发送</span>
          </el-input>
        </div>
      </div>
    </div>
    <div class="flex-1 mt30 mr30 mb30">
      <QaCorrect :qaData="qaData" :session_id="session_id"></QaCorrect>
    </div>
  </div>
</template>

<script>
import 'xgplayer/dist/index.min.css';
import FlvPlugin from "xgplayer-flv";
import Player from "xgplayer";
import {baseURL, flvURL, remoteFlvURL} from "../utils/config";
import QaCorrect from '../components/qa-correct.vue'
import CaptionsBox from '../components/captions-box.vue'
import axios from "axios";

export default {
  props: ['scriptData'],
  components: {
    CaptionsBox,
    QaCorrect
  },
  data () {
    return {
      scripts: [],
      session_id: '',
      playerWidth: 408,
      inputQa: '',
      cursorId: 0,
      faqList: [],
      qaData: [],
    }
  },
  watch: {
    faqList() {
      this.$nextTick(() => {
        this.updateOpacity()
      })
    }
  },
  mounted() {
    this.scripts = this.scriptData?.script || []
    this.session_id = this.scriptData?.session_id
    if (!this.scripts.length || !this.session_id) {
      // this.$router.replace({ path: '/' })
    }
    this.$nextTick(() => {
      const tempHeight = this.$refs.playerWrap.offsetHeight
      this.playerWidth = tempHeight * 0.56
      this.generatePlayer()

      // 滚动动态变更评论透明度
      const container = this.$refs.scrollContainer;
      container.addEventListener('scroll', this.updateOpacity);
      this.faqList.push({ id: this.getId(), t: 'a', text: '欢迎' } )
    })
  },
  methods: {
    async question() {
      if (this.inputQa.trim().length === 0) {
        return
      }
      const question = this.inputQa.trim()
      this.faqList.push({
        id: this.getId(),
        t: 'q',
        text: question
      })
      this.goTalkBottom()
      const tempQ = this.inputQa
      this.inputQa = ''
      const {data: {code, data}} = await axios.get(baseURL + '/v3/session/faq_wav', {
        params: {
          session_id: this.session_id,
          question: tempQ
        }
      })
      if (code !== 0) {
        this.$message.warning("请求错误， 返回值报错码不为0")
        return;
      }
      this.faqList.push({
        id: this.getId(),
        t: 'a',
        text: data.answer,
        key: data.key,
      })
      this.qaData.push({
        question: question,
        answer: data.answer,
        key: data.key,
        showCorrect: true,
        contenteditable: false,
        isCorrected: false
      })
      this.goTalkBottom()
    },
    getId() {
      return this.cursorId++
    },
    goTalkBottom(){
      this.$nextTick(() => {
        let container = this.$refs.scrollContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    generatePlayer() {
      if (FlvPlugin.isSupported()) {
        const url = localStorage.getItem('flvURL') || (this.$route.query?.remote ? remoteFlvURL : flvURL)
        console.log(url)
        const myPlayer = new Player({
          id: 'my-player',
          url: url,
          width: '100%',
          height: '100%',
          autoplay: true,
          controls: false,
          videoFillMode: 'contain',
          closeVideoClick: true,
          closeVideoDblclick: true,
          isLive: true,
          plugins: [FlvPlugin],
        })
      } else {
        this.$message.warning("该浏览器暂不支持flv文件")
      }
    },
    updateOpacity() {
      const container = this.$refs.scrollContainer;
      const children = Array.from(container.children);
      const containerRect = container.getBoundingClientRect();
      const visibleElements = children.filter(element => {
        const rect = element.getBoundingClientRect();
        return rect.top < containerRect.bottom  && rect.bottom > containerRect.top;
      });

      visibleElements.forEach((element, index) => {
        const opacity = 0.4 + 0.6 *  (index + 1) / visibleElements.length;
        element.style.opacity = opacity.toString();
      });
    }
  }
}
</script>

<style lang="scss">
.live-page {
  height: 100vh;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
}
.video-box{
  margin-right: 50px;
  margin-left: 50px;
  border-radius: 20px;
  height: calc(100vh - 60px);
  display: flex;
  align-items: flex-end;
  padding: 0 0 15px 15px;
  box-sizing: border-box;
  overflow: hidden;
  .player-box{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    #my-player {
    }
  }

  .faq-box-wrap {
    width: 100%;
    z-index: 999;
    position: relative;
  }
  .faq-box {
    max-width: 68%;
    max-height: 220px;
    overflow-y: auto;
    z-index: 10;
    margin-bottom: 70px;
    .faq-item {
      display: inline-block;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 20px;
      box-sizing: border-box;
      padding: 8px 15px;
      text-align: left;
      word-wrap: break-word;
      word-break: break-all;
      font-size: 18px;
      color: #FFF;
      margin-top: 10px;
    }
  }
  .faq-box::-webkit-scrollbar {
    display: none;
  }
  .question-btn-box{
    padding-right: 15px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0px;
    border-radius: 30px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .el-input__inner {
      height: 48px;
      border: none;
      font-size: 18px;
      padding: 20px 20px;
      border-radius: 30px;
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.6);
    }
    .el-input__suffix {
      margin-right: 15px;
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #FF7337;
      font-weight: 600;
    }
  }
}

.no-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.no-scrollbar::-webkit-scrollbar-track {
  background: #292929;
}
.no-scrollbar::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 8px;
}
.no-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
  border-radius: 8px;
}
</style>
