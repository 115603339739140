<template>
  <div>
    <div class="flex">
      <div class="flex-1">
        <div class="video-box"></div>
      </div>
      <div class="flex-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageT'
}
</script>

<style lang="scss" scoped>
.video-box {}
</style>
