<template>
  <button class="flex-center relative">
    <img :class="{ hidden: !loading }" class="mr10 z100" src="https://cdn-static.chanmama.com/res/upload/2024/03/12/rolling-icon.svg" width="20" height="20" alt="">
    <span class="z100 pr20">
      <slot></slot>
    </span>
    <TabBox></TabBox>
  </button>
</template>

<script>
import TabBox from "@/pages/config-page/components/tab2.vue";

export default {
  props: ['loading', 'text'],
  components: {
    TabBox
  },
  data () {
    return {
      blur2: false
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
button {
  text-decoration: none;
  position: relative;
  border: none;
  font-size: 18px;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
  color: #fff;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 300%;
  border-radius: 8px;
  z-index: 1;

  width: 262px;
  height: 50px;
  margin-top: 50px;
  margin-bottom: 20px;
}

button:hover {
  animation: ani 8s linear infinite;
  border: none;
}
@keyframes ani {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

button::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 8px;
}

button:hover::before {
  animation: aa 20s linear 1;
}
@keyframes aa {
  0% {
    filter: blur(2px);
  }
  5% {
    filter: blur(20px);
  }

  100% {
    filter: blur(30px);
  }
}

button:active {
  background: linear-gradient(32deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
}
</style>
