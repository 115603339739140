<template>
  <div class="goods-wrap">
    <div class="relative">
      <!-- 商品卡 -->
      <div class="good-card">
        <img src="https://cdn-static.chanmama.com/sub-module/static-file/b/f/9b8d932ee8?x-oss-process=image/resize,w_168" alt="">
        <div>{{ formData.name }}</div>
      </div>
      <div class="form-wrapper">
        <el-form ref="form" :model="formData" label-width="80px">
          <el-form-item label="商品描述">
            <el-input v-model="formData.desc" type="textarea" :autosize="{ minRows: 2, maxRows: 3 }" placeholder="请输入商品描述"></el-input>
          </el-form-item>
          <el-form-item label="卖点信息">
            <el-input v-model="formData.sell_point" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" placeholder="请输入商品卖点信息"></el-input>
          </el-form-item>
          <div class="form-item-row">
            <el-form-item class="flex-1" label="原始价格">
              <el-input v-model="formData.original_price" placeholder="请输入商品原始价格"></el-input>
            </el-form-item>
            <el-form-item class="flex-1 ml60" label="当前价格">
              <el-input v-model="formData.current_price" placeholder="请输入商品当前价格"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-row">
            <el-form-item class="flex-1" label="折扣信息">
              <el-input v-model="formData.special_offers" placeholder="请输入商品折扣信息"></el-input>
            </el-form-item>
            <el-form-item class="flex-1 ml60" label="发货地">
              <el-input v-model="formData.shipments_province" placeholder="请输入发货地"></el-input>
            </el-form-item>
          </div>
          <el-form-item class="flex-1" label="小店名称">
            <el-input v-model="formData.shop_name" placeholder="请输入小店名称"></el-input>
          </el-form-item>
          <el-form-item class="flex-1" label="品牌名称">
            <el-input v-model="formData.brand_name" placeholder="请输入品牌名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex justify-content-center" @click="createSession">
        <Btn :loading="loading">生成带货文案</Btn>
      </div>
    </div>
    <div class="blur"></div>
  </div>
</template>

<script>
import TabBox from './tab2.vue'
import Btn from "@/components/btn.vue";

import axios from "axios";
import {baseURL} from "@/utils/config";

export default {
  props: ['goodInfo', 'channel'],
  components: {
    TabBox,
    Btn
  },
  data () {
    return {
      loading: false,
      session_id: '',
      scriptList: [],
      formData: {
        ...this.goodInfo,
        num: 5,
        words_limit: 1,
        anchor_name: "小蝉蝉",
        selling_style: '通用风格',
      },
    }
  },
  methods: {
    async createSession() {
      if (this.loading) {
        return
      }
      const judgeList = [
        {condition: this.formData.name, msg: '商品名称不可为空'},
        {condition: this.formData.current_price, msg: '当前价格不可为空'},
        {condition: parseInt(this.formData.num) > 0, msg: '文案数量必须要大于0'}
      ]
      const result = judgeList.find(item => !item.condition)
      if(result){
        this.$message.warning(result.msg)
        return
      }
      let req = {
        ...this.formData,
        "num": parseInt(this.formData.num),
        channel: this.channel
      };
      try {
        this.loading = true
        const resData = await axios.post(baseURL + '/v2/session', req)
        const res = resData.data
        if (res.code !== 0) {
          this.$message.warning(res.msg)
          return;
        }
        this.session_id = res.data.session_id;
        this.scriptList = res.data.scripts?.map(v => {
          return {
            text: v
          }
        })
        setTimeout(() => {
          this.loading = false
          this.hasGenerateScript = true
          this.$emit('onGenerateScript', {
            session_id: this.session_id,
            scriptList: this.scriptList
          })
        }, 3000)
      } catch (e) {
        console.error(e)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 3000)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
.goods-wrap {
  position: relative;
  background: #22232D;
  padding: 20px 24px;
  width: 555px;
  border-radius: 20px;
}
.button {
  width: 262px;
  height: 50px;
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #FFF;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  background: linear-gradient(91.68deg, #5A97FE 6.58%, #DB73DD 99.57%);
  .blur {
    position: absolute;
    bottom: -20%;
    width: 80%;
    height: 80%;
    filter: blur(40px);
    opacity: 0.5;
    background: linear-gradient(164.22deg, #5398FF 11.02%, #FF69D5 95.21%);
  }
  .z100 {
    z-index: 100;
  }
}
.blur {
  opacity: 0.2;
  z-index: -1;
  position: absolute;
  top: 20%;
  left: -100px;
  width: 306px;
  height: 348px;
  border-radius: 50%;
  filter: blur(80px);
  background: linear-gradient(164.22deg, #5398FF 11.02%, #FF69D5 95.21%);
}
.good-card {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-radius: 20px;
  background: #000;
  img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
  div {
    font-size: 24px;
    font-weight: 600;
    margin-left: 30px;
    color: rgba($color: #FFF, $alpha: 0.6);
  }
}
.form-wrapper {
  z-index: 999;
  margin-top: 18px;
  overflow: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  .form-item-row {
    width: 100%;
    display: flex;
    &::v-deep {
      .el-form-item__label {
        margin-top: 12px;
      }
    }
  }
  &::v-deep {
    .add-goods-input{
      width: 707px;
      margin-top: 200px;
      .el-input__inner{
        border-radius: 12px;
        height: 56px;
        border: 1px solid rgba(255, 255, 255, 0.6);
      }
    }
    .question-btn-box .el-input__inner{
      border: none;
    }
    .el-form-item {
      margin-bottom: 20px;
      font-family: PingFang SC;
      &:last-child {
        margin-bottom: 0
      }
      &:nth-child(n+3) {
        .el-form-item__label {
          margin-top: 12px;
        }
      }
    }
    .el-form-item__content {
      padding-right: 5px;
    }
    .el-form-item__label {
      color: rgba(255, 255,255,.4);
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      margin-top: 14px;
    }
    .el-input__inner {
      height: 50px;
      padding: 22px;
      font-size: 15px;
      background-color: transparent;
      border: 1px solid #4a4a4a;
      color: #CBCBCB;
      font-family: PingFang SC;
    }
    .el-input__inner::placeholder {
      color: #666;
    }
    .el-input-group__append{
      padding-top: 3px;
      width: 33px;
      background: transparent;
      border: 1px solid #4a4a4a;
      border-left: none;
      transform: translateX(-1px);
    }
    .el-textarea {
      border: 1px solid rgba(255, 255, 255, .1);
      border-radius: 5px;
      padding-bottom: 8px;
    }
    .el-textarea__inner{
      min-height: 42px !important;
      padding: 12px 15px;
      font-size: 15px;
      background-color: transparent;
      border: none;
      color: #CBCBCB;
      font-family: PingFang SC;
    }
    .el-textarea__inner::placeholder {
      color: #666;
    }
    .el-textarea__inner::-webkit-scrollbar {
      width: 8px;
    }
    .el-textarea__inner::-webkit-scrollbar-track {
      background: transparent;
    }
    .el-textarea__inner::-webkit-scrollbar-thumb {
      background: #4a4a4a;
      border-radius: 8px;
    }
    .el-textarea__inner::-webkit-scrollbar-thumb:hover {
      background: #5a5a5a;
      border-radius: 8px;
    }
  }
}

</style>
