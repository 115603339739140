<template>
  <div class="qa-correct">
    <div class="wrap">
      <div class="title">
        <div class="font-weight-600 fs20 cfff">互动模型校正</div>
        <div class="tips">
          <span></span>用户向主播提问后，数字人主播会根据蝉妈妈直播语言模型在讲解中插入语音回答。<br>
          <span></span>当管理员发现模型回答得不完美后可点击“校正”按钮更正回答。<br>
          <span></span>当用户再问出相似的问题后，模型则会根据校正后的内容回答。<br>
        </div>
      </div>
      <div class="flex-1 relative">
        <div class="absolute full">
          <div class="qa-box scroll-transition" ref="qaBox" v-if="qaData.length" @wheel="updateLastTime">
            <div class="item" v-for="(v, i) in qaData" :key="v.a_key">
              <div class="question">{{ v.question }}
                <svg class="icon" width="12" height="19" viewBox="0 0 12 19" fill="none">
                  <path d="M2 13.3708C-0.666664 11.8312 -0.666667 7.98223 2 6.44263L11.75 0.813468L11.75 19L2 13.3708Z" fill="#3F4153"/>
                </svg>
              </div>
              <div class="answer">
                <div class="content">
                  <svg class="icon" width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.75 5.62916C12.4167 7.16876 12.4167 11.0178 9.75 12.5574L-7.94959e-07 18.1865L0 -6.88454e-07L9.75 5.62916Z" fill="#0F0F11"/>
                  </svg>
                  <div :id="'edit_' + v.a_key" v-if="v.contenteditable" :contenteditable="true" v-html="v.answer"></div>
                  <div v-else>{{ v.answer }}</div>
                  <div style="min-height: 36px">
                    <div v-if="v.contenteditable" class="flex justify-content-end mt15 fs16 font-weight-600">
                      <span @click="onCancel(v, i)" class="cursor-pointer" style="color: #858585;">取消</span>
                      <span @click="onConfirm(v, i)" class="cursor-pointer ml25" style="color: #5B97FE">确认</span>
                    </div>
                    <div v-else class="flex justify-content-end mt15">
                      <div v-if="v.isCorrected" class="fs16 c626262 fs16 flex flex-1">
                        <div v-if="isAdmin" class="flex-1 text-align-right">
                          <img src="../assets/ok.png" width="12" alt="">
                          <span class="ml5">已校正</span>
                        </div>
                        <div v-else class="flex-1">
                          <div class="text-align-right">已校正,待审核</div>
                          <div class="fs12 flex pt12 mt12" style="border-top: 1px solid rgba(255, 255, 255, 0.1)">
                            <img class="mt2 mr4" width="12" height="12" src="https://cdn-static.chanmama.com/sub-module/static-file/c/d/b1fa1d6f8f" alt="">
                            <span>该功能仅对管理员开放，您为普通用户仅支持虚拟体验</span>
                          </div>
                        </div>
                      </div>
                      <span v-if="v.showCorrect" @click="onCorrect(v, i)" class="fs16 cursor-pointer font-weight-600" style="color: #5B97FE">校正</span>
                    </div>
                  </div>
                </div>
                <div class="ai-icon ml20">AI</div>
              </div>
            </div>
          </div>
          <div v-else style="opacity: 0.4; margin-top: 45%;" class="text-align-center">
            <img width="128" height="128" src="https://cdn-static.chanmama.com/sub-module/static-file/c/5/c9d2775128" alt="">
            <div>暂时没有互动消息</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {baseURL} from "@/utils/config";

export default {
  props: ['session_id', 'isAdmin', 'demo_id'],
  data () {
    return {
      lastTime: 0,
      qaData: []
    }
  },
  mounted() {
    this.timer = setInterval(this.tick, 1000);
    document.addEventListener('keydown', this.updateLastTime)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.updateLastTime)
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    tick() {
      this.getQa()
    },
    async getQa() {
      try {
        const {data: {code, data}} = await axios.get(baseURL + '/v5/recently_qa_to_edit', {
          params: {
            session_id: this.session_id,
            demo_id: this.demo_id,
          }
        })
        if (code !== 0) {
          console.error("请求错误， 返回值报错码不为0")
          return;
        }
        this.addQa(data?.qas || [])
      } catch (e) {
        console.error(e)
      }
    },
    addQa(list) {
      for (let i = 0; i < list.length; i ++) {
        const item = list[i]
        const fd = this.qaData.find(v => v.a_key == item.a_key)
        if (!fd) {
          this.qaData.push({
            question: item.q,
            answer: item.a,
            a_key: item.a_key,
            q_key: item.q_key,
            showCorrect: true,
            contenteditable: false,
            isCorrected: false
          })
        }
      }
      if (this.qaData.length > 150) {
        this.qaData.splice(0, 100)
      }
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },
    updateLastTime () {
      this.lastTime = Date.now()
    },
    select (el) {
      const range = document.createRange()
      range.selectNodeContents(el)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
    },
    onCorrect (v) {
      this.lastTime = Date.now()
      v.temp = v.answer
      v.contenteditable = true
      this.$nextTick(() => {
        const el = document.getElementById('edit_' + v.a_key)
        if (el) {
          this.select(el)
        }
      })
    },
    onCancel(v) {
      v.answer = v.temp
      v.contenteditable = false
    },
    onConfirm(v, i) {
      const text = document.getElementById('edit_' + v.a_key)?.innerText
      if (text) {
        v.answer = text
        if (this.isAdmin) {
          this.saveQa(v.question, v.answer);
        }
      }
      v.contenteditable = false
      v.showCorrect = false
      v.isCorrected = true
    },
    scrollToBottom () {
      if (Date.now() - this.lastTime < 1000 * 5) {
        return
      }
      const element = this.$refs.qaBox;
      if (element) element.scrollTop = element.scrollHeight;
    },
    async saveQa(q, a) {
      try {
        const resData = await axios.post(baseURL + '/v4/qa/save', {
          session_id: this.session_id,
          q: q,
          a: a,
          demo_id: this.demo_id,
        })
        const res = resData.data
        if (res.code !== 0) {
          this.$message.warning(res.msg)
        }
      } catch (e) {
        console.error(e)
      } finally { }
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-transition {
  scroll-behavior: smooth;
  transition: scroll-behavior 0.5s ease; /* 可根据需要调整过渡时间和缓动效果 */
}
.qa-correct {
  position: relative;
  height: 100%;
  display: flex;
}
.wrap {
  margin: 20px 0 20px 0;
  padding: 5px 0 5px;
  width: 100%;
  position: relative;
  background: #23242E;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}
.title {
  padding: 15px 20px;
  z-index: 999;
  .tips {
    opacity: 0.95;
    margin-top: 15px;
    background: linear-gradient(91.63deg, #54E3C0 0.16%, #56A4FF 97.93%);
    border-radius: 8px;
    padding: 15px 15px 15px 20px;
    font-size: 14px;
    span {
      position: relative;
      &::before {
        display: inline-block;
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        left: -10px;
        top: 8px;
        border-radius: 10px;
        background: #fff;
      }
    }
  }
}
.qa-box {
  transition: scroll-behavior 0.3s ease-in-out;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
  .item {
    margin-top: 30px;
    padding-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    &:last-child {
      border-bottom: none;
    }
    .question {
      border-radius: 12px;
      display: inline-block;
      background: #3F4153;
      padding: 12px 20px 12px 20px;
      margin-left: 8px;
      margin-bottom: 22px;
      font-size: 16px;
      position: relative;
      .icon {
        position: absolute;
        top: 10px;
        left: -18px;
        width: 25px;
        height: 25px;
        z-index: 0;
      }
    }
    .answer {
      display: flex;
      justify-content: right;
      position: relative;
      .content {
        opacity: 0.8;
        font-size: 16px;
        padding: 20px 20px 10px 20px;
        border-radius: 12px;
        width: 70%;
        background: #0F0F11;
        position: relative;
        .icon {
          position: absolute;
          top: 20px;
          right: -18px;
          width: 25px;
          height: 25px;
        }
      }
      [contenteditable]:focus {
        outline: none;
      }
    }
  }
}
.opacity30 {
  opacity: .3;
}
.ai-icon {
  flex-shrink: 0;
  background: linear-gradient(180deg, #6675FF 0%, #BD45F5 100%);
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 50px;
  margin-top: 5px;
}
</style>
