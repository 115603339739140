<template>
  <div class="wrap">
    <div ref="scriptBox" class="script-box" :style="{ transform: `translateY(${translateY}px)` }">
      <div class="text" :class="{ active: curKey === v.key, qa: v.type == 'qa' }" v-for="(v, i) in scripts" :id="'key_' + v.key" :key="v.key">
        {{ v.text }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {baseURL, keyURL, remoteKeyURL} from "../utils/config";

const screenHeight = window.innerHeight
const initScreenHeight = screenHeight / 2
export default {
  props: ['faqList', 'session_id', 'scripts'],
  data() {
    return {
      timer: null,
      translateY: initScreenHeight,
      speechRate: 1,
      maxTranslateY: 0,
      curKey: '', // 当前解说的文案key
    }
  },
  watch: {
    // faqList() {
    //   const item = this.faqList[this.faqList.length - 1]
    //   if (item?.t == 'a') {
    //     this.setKey(item.key)
    //   }
    // },
    curKey () {
      this.setClientHeight();
      this.$nextTick(() => {
        this.setTranslateY()
        this.setSpeechRate()
      })
      console.log('curKey change = ', this.curKey)
      const fd = this.scripts.find(v => v.key == this.curKey)
      if (fd) {
        console.log('cur text =', fd.text)
      }
    }
  },
  mounted() {
    this.setClientHeight();
    this.timer = setInterval(this.tick, 100);
    this.getKey();
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    tick() {
      if (this.curKey) {
        if (this.translateY > this.maxTranslateY) {
          this.translateY -= this.speechRate
        }
      }
    },
    async getKey() {
      while (true) {
        await new Promise(r => setTimeout(r, 500))
        try {
          const url = localStorage.getItem('keyURL') || (this.$route.query?.remote ? remoteKeyURL : keyURL)
          const resData = await axios.get(url + '?channel=' + (this.$route.query.channel || 1))
          const res = resData.data
          console.log('get key =', res.key)
          if (res.key) {
            this.setKey(res.key)
          } else {
            this.speechRate = 0
          }
        } catch (e) {
          // console.error(e)
        }
      }
    },
    setKey (k) {
      const index = this.scripts.findIndex(v => v.key == k)
      if (index == -1) {
        console.log('未在话术列表匹配key=', k)
        // 当前话术没有， 尝试从问答获取
        const index2 = this.faqList.findIndex(v => v.key == k)
        if (index2 == -1) {
          console.log('未在问答列表匹配key=', k)
        } else {
          // 在当前key后面插入回答
          const idx = this.curKey ? this.scripts.findIndex(v => v.key == this.curKey) : 0
          if (idx != -1) {
            this.scripts.splice(idx + 1, 0, {
              type: 'qa',
              text: this.faqList[index2].text,
              key: this.faqList[index2].key
            })
            console.log('新增，', k)
            this.$nextTick(() => {
              this.setClientHeight()
              this.setKey(k)
            })
          } else {
            console.error('key错误,', k)
          }
        }
      } else {
        if (this.curKey != k) {
          console.log('set key =', k)
          this.curKey = k
        }
      }
    },
    setTranslateY () {
      let h = 0
      let isFind = false
      let itemIndex = -1;
      for (let i = 0; i < this.scripts.length; i++) {
        if (this.scripts[i].key == this.curKey) {
          isFind = true
          itemIndex = i;
          break
        } else {
          h += (this.scripts[i].clientHeight || 0) + 20
        }
      }
      if (isFind) {
        this.translateY = -h + initScreenHeight
        this.maxTranslateY = this.translateY - ((this.scripts[itemIndex].clientHeight || 0) + 20) - (initScreenHeight * 0.5)
        console.log('translateY = ', this.translateY)
        console.log('maxTranslateY = ', this.maxTranslateY)
      } else {
        console.log('未匹配', this.curKey)
      }
    },
    setClientHeight () {
      this.scripts.forEach((v) => {
        if (!v.clientHeight) {
          v.clientHeight = document.getElementById('key_' + v.key)?.clientHeight || 0
        }
      })
    },
    setSpeechRate() {
      const fd = this.scripts.find(v => v.key === this.curKey)
      if (fd) {
        const speechRate = ((fd.clientHeight + 20) / (fd.text.length / 4)) / 10;
        console.log('speechRate = ', speechRate, '高度： ', fd.clientHeight, '， 字数，',  fd.text.length)
        if (speechRate > 0 && speechRate < 5) {
          this.speechRate = speechRate
        } else {
          this.speechRate = 1
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: rgb(255, 255, 255, .9);
  mask-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.7) 40%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.6) 60%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0) 100%);
  font-size: 28px;
  font-weight: 700;
  line-height: 1.4;
  overflow: hidden;
  unicode-bidi: plaintext;
  .script-box {
    transition: all 0.3s;
  }
  .text {
    margin-bottom: 20px;
    &.active {
      color: #FFFFFF;
      // color: red;
    }
    &.qa {
      color: #1EBF98;
    }
  }
}
</style>
