<template>
  <div class="page-wrap">
    <div class="flex h100p">
      <div class="flex w100p">
        <div class="relative flex flex-column justify-content-center">
          <div v-show="!hasGenerateScript">
            <div class="flex justify-content-center">
              <div>
                <div style="font-size: 90px;"  class="font-weight-600 wow fadeInLeft" data-wow-delay="0.2s">Step&nbsp;2</div>
                <div style="font-size: 40px" class="font-weight-600 mt15 wow fadeInLeft" data-wow-delay=".2s">
                  系统会根据商品链接自动<br>
                  提取商品基础信息
                </div>
              </div>
            </div>
            <div class="big-img-box wow fadeInUp" data-wow-delay="0s">
              <img src="https://cdn-static.chanmama.com/sub-module/static-file/e/2/236528a887" alt="">
            </div>
          </div>
          <div v-show="hasGenerateScript">
            <div class="flex justify-content-center">
              <div>
                <div style="font-size: 90px;"  class="font-weight-600 wow fadeInLeft" data-wow-delay="0.2s">Step&nbsp;3</div>
                <div style="font-size: 40px" class="font-weight-600 mt15 wow fadeInLeft" data-wow-delay=".2s">
                  系统会根据所提取商品信息<br>
                  结合蝉妈妈语言模型<br>
                  自动生成带货文案
                </div>
              </div>
            </div>
            <div class="big-img-box wow fadeInUp" data-wow-delay="0s">
              <img src="https://cdn-static.chanmama.com/sub-module/static-file/5/d/efdcf7d5d2" alt="">
            </div>
          </div>
        </div>
        <div class="flex flex-column align-items-center justify-content-center relative">
          <div v-if="!showGoodsInfo" style="width: 432px" class="text-align-center">
            <div class="container-loading">
              <div class="dash first"></div>
              <div class="dash seconde"></div>
              <div class="dash third"></div>
              <div class="dash fourth"></div>
            </div>
            <el-progress style="margin-top: 120px" :percentage="progress" :stroke-width="10" :show-text="false" color="#6893FA" define-back-color="#666"></el-progress>
          </div>
          <div class="goods-info-box" :class="{ show: showGoodsInfo, slideUp: hasGenerateScript }">
            <GoodsInfo @onGenerateScript="onGenerateScript" v-if="showGoodsInfo" :goodInfo="goodInfo" :channel="channel"></GoodsInfo>
          </div>
          <div class="script-container-wrap" :class="{ show: hasGenerateScript }">
            <div class="script-container" v-if="hasGenerateScript">
              <div class="flex-1 flex flex-direction-column relative mt50" style="overflow: hidden;">
                <div class="w100p flex justify-content-space-between align-items-center">
                  <div class="fs24 font-weight-600">直播带货文案</div>
                </div>
                <div class="divede-line mt24 mb10"></div>
                <div class="flex-1 no-scrollbar" style="overflow: auto;">
                  <div class="mb40" v-for="(v, i) in scriptList" :key="i">
                    <MyTextarea ref="MyTextarea" :text="v.text" :currentIndex="i"></MyTextarea>
                  </div>
                </div>
                <div style="position: absolute; bottom: 0;left: 0;right: 0; height: 55px; background: linear-gradient(176.06deg, rgba(26, 27, 34, 0) 2.97%, #1A1B22 96.54%);"></div>
              </div>
              <div>
                <div class="divede-line mb30 mt10"></div>
                <div class="flex justify-content-space-between mt40" style="width: 100%;">
                  <SelectWithTitle class="flex-1" title="主播名称" :option="nameOption" :showArrow="false" v-model="formData.anchor_name"></SelectWithTitle>
                  <div style="width: 20px"></div>
                  <SelectWithTitle class="flex-1" title="主播风格" :option="styleOption" v-model="formData.selling_style"></SelectWithTitle>
                </div>
                <div class="flex justify-content-center pb50" @click="handlePushFlow">
                  <Btn :loading="loading">开启直播推流</Btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyTextarea from '@/components/MyTextarea.vue'
import axios from "axios";
import {baseURL} from "@/utils/config";
import GoodsInfo from './goods-info.vue'
import SelectWithTitle from "@/components/select-with-title.vue";
import TabBox from './tab2.vue'
import Btn from "@/components/btn.vue";


export default {
  props: ['goodUrl', 'channel', 'demo_id'],
  components: {
    GoodsInfo,
    MyTextarea,
    SelectWithTitle,
    TabBox,
    Btn
  },
  data () {
    return {
      isCn: true,
      loading: false,
      interval: null,
      progress: 0,
      goodInfo: null,
      session_id: '',
      enScriptList: [],
      scriptList: [],
      hasGenerateScript: false,
      formData: {
        anchor_name: "小蝉蝉",
        selling_style: '通用风格',
      },
      nameOption: [
        {
          label: '小蝉蝉',
          value: '小蝉蝉'
        }
      ],
      styleOption: [
        {
          label: '通用风格',
          value: '通用风格'
        },{
          label: '李佳琦',
          value: '李佳琦',
        },{
          label: '杨雨盈',
          value: '杨雨盈',
        }
      ]
    }
  },
  computed: {
    showGoodsInfo () {
      return !!this.goodInfo
    }
  },
  mounted() {
    this.setProgress()
    this.getGoodsInfo()
  },
  methods: {
    setProgress () {
      this.interval = setInterval(() => {
        if (this.progress < 98) {
          this.progress+= 2
        } else {
          clearInterval(this.interval)
        }
      }, 90)
    },
    async onGenerateScript (d) {
      this.scriptList = d.scriptList
      this.session_id = d.session_id
      this.hasGenerateScript = true
    },
    async getGoodsInfo (url) {
      this.goodUrl = url ?? this.goodUrl
      if(!this.goodUrl){
        this.$message.warning("请输抖音商品链接！")
        return
      }
      try {
        this.loading = true
        const {data: {data, code, msg}} = await axios.post(baseURL + '/v3/get_url_info', {
          url: this.goodUrl
        })
        if (code !== 0) {
          this.$message.warning(msg)
          return
        }
        const info = JSON.parse(data.information)
        let tempDesc = ''
        for (let [key, value] of Object.entries(info.desc.product_format)){
          tempDesc += `${key}：${value}；`
        }
        tempDesc += info.desc.ocr.join('、')
        let tempSellPoint = ''
        for (let [key, value] of Object.entries(info.sell_point)){
          tempSellPoint += `${key}：${value.join('、')}；`
        }
        const formData = {
          ...info,
          url: this.goodUrl,
          desc: tempDesc,
          sell_point: tempSellPoint
        }
        setTimeout(() => {
          this.goodInfo = formData
        }, 5000)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async handlePushFlow () {
      if (!this.session_id) {
        this.$message.warning('请先生成带货脚本')
        return
      }
      let text = ''
      if(this.isCn){
        this.scriptList.forEach((v, i) => {
          const tempStr = this.$refs.MyTextarea[i]?.getText()
          v.text = tempStr.replace(/\n{3,}/g, "\n\n")
        })
        text = this.scriptList.map(v => {
          return v.text
        })
      }else{
        this.enScriptList.forEach((v, i) => {
          const tempStr = this.$refs.MyTextarea[i]?.getText()
          v.text = tempStr.replace(/\n{3,}/g, "\n\n")
        })
        text = this.enScriptList.map(v => {
          return v.text
        })
      }
      const req = {
        session_id: this.session_id,
        channel: this.channel,
        lang: this.isCn ? 'cn' : 'en',
        text
      }
      try {
        this.loading = true
        const resData = await axios.post(baseURL + '/v3/create_wav', req)
        const res = resData.data
        if (res.code !== 0) {
          this.hasGenerateScript = false
          this.$message.warning(res.msg)
          return;
        }
        const scriptData = {
          script: res.data,
          session_id: this.session_id
        }
        await this.getVideoProgress(scriptData)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async getVideoProgress(scriptData) {
      while (true) {
        await new Promise(r => setTimeout(r, 1000))
        try {
          const { channel, demo_id } = this
          const url = baseURL + '/get_key'
          const resData = await axios.get(url, { params: {channel, demo_id} })
          const res = resData.data
          const data = res?.data
          if (data?.key) {
            this.$emit('step', 'page4', {scriptData});
            break
          }
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
.page-wrap {
  height: 100%;
  color: #fff;
  margin-left: calc(50vw - 600px);


  .goods-info-box {
    opacity: 0;
    transform: translateX(220px);
    transition: all 1.2s ease;
    &.show {
      opacity: 1;
      transform: translateX(0px);
    }
    &.slideUp {
      opacity: 0;
      transform: translateY(-100vh);
    }
  }
  .script-container-wrap {
    background: #1A1B22;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 1.2s ease;
    opacity: 0;
    transform: translateY(100vh);
    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .script-container {
    height: 100vh;
    width: 622px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0px 0 0px;
    .divede-line {
      width: 100%;
      height: 1px;
      background: rgba($color: #FFF, $alpha: 0.2);
    }
    .no-scrollbar::-webkit-scrollbar {
      width: 4px;
    }
    .no-scrollbar::-webkit-scrollbar-track {
      background: transparent;
    }
    .no-scrollbar::-webkit-scrollbar-thumb {
      background: #FFF;
      border-radius: 10px;
    }
    .no-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #FFF;
      border-radius: 10px;
    }
  }
  .button {
    width: 262px;
    height: 50px;
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #FFF;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    background: linear-gradient(91.68deg, #5A97FE 6.58%, #DB73DD 99.57%);
    .blur {
      position: absolute;
      bottom: -20%;
      width: 80%;
      height: 80%;
      filter: blur(40px);
      opacity: 0.5;
      background: linear-gradient(164.22deg, #5398FF 11.02%, #FF69D5 95.21%);
    }
    .z100 {
      z-index: 100;
    }
  }
}

.big-img-box {
  position: relative;
  margin-top: -200px;
  top: 0;
  left: -80px;
  width: 600px;
  height: 600px;
  img {
    width: 100%;
    height: 100%;
  }
}

.container-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.dash {
  margin: 0 15px;
  width: 22px;
  height: 8px;
  border-radius: 8px;
  background: rgb(82, 159, 246);
  box-shadow: rgb(82, 159, 246) 0 0 15px 0;
}

.first {
  margin-right: -18px;
  transform-origin: center left;
  animation: spin 3s linear infinite;
}

.seconde {
  transform-origin: center right;
  animation: spin2 3s linear infinite;
  animation-delay: .2s;
}

.third {
  transform-origin: center right;
  animation: spin3 3s linear infinite;
  animation-delay: .3s;
}

.fourth {
  transform-origin: center right;
  animation: spin4 3s linear infinite;
  animation-delay: .4s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(360deg);
  }

  30% {
    transform: rotate(370deg);
  }

  35% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-180deg);
  }

  35% {
    transform: rotate(-190deg);
  }

  40% {
    transform: rotate(-180deg);
  }

  78% {
    transform: rotate(-180deg);
  }

  95% {
    transform: rotate(-360deg);
  }

  98% {
    transform: rotate(-370deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spin3 {
  0% {
    transform: rotate(0deg);
  }

  27% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(180deg);
  }

  45% {
    transform: rotate(190deg);
  }

  50% {
    transform: rotate(180deg);
  }

  62% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  80% {
    transform: rotate(370deg);
  }

  85% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin4 {
  0% {
    transform: rotate(0deg);
  }

  38% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(-360deg);
  }

  65% {
    transform: rotate(-370deg);
  }

  75% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
</style>
