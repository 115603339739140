<template>
  <el-dropdown trigger="click" @command="handleCommand" placement="bottom-start">
    <div class="select-with-title">
      <div class="font-weight-600">{{ title }}</div>
      <div class="flex-center">
        <span class="cfff">{{ name }}</span>
      </div>
    </div>
    <el-dropdown-menu :slot="option && option.length ? 'dropdown' : 'noShow'">
      <el-dropdown-item v-for="item in option" :key="item.value" :command="item.value">{{ item.label }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'select-with-title',
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    showArrow: {
      default: true
    },
    option: {
      type: Array, // [{label: '',value: ''}]
      default: () => []
    }
  },
  computed: {
    name () {
      const rst = this.option.find(({value}) => value == this.value)
      return rst?.label || '请选择'
    }
  },
  methods: {
    handleCommand (v) {
      this.$emit('input',v)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-with-title {
  width: 100%;
  height: 44px;
  padding: 10px 14px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 15px;
  color: rgba($color: #fff, $alpha: 0.5);
  border: 1px solid #686868;
}
</style>
<style lang="scss">
.el-dropdown-menu {
  width: 308px;
}
</style>
