<template>
  <div class="page-2-wrapper">
    <!-- 搜索框 -->
    <div class="search-input relative">
      <input v-model.trim="goodUrl" @keydown.enter="handleSubmit()" placeholder="输入商品链接" />
      <img
        src="https://cdn-static.chanmama.com/sub-module/static-file/3/0/583693c0cf"
        class="search-icon"
        width="64"
        @click="handleSubmit()"
        alt=""
      />
    </div>
    <div class="mt70 flex-center cfff opacity6">
      <div class="fs30">数据来自 chanmama.com & chanxuan.com</div>
    </div>
    <div class="good-wrapper mt70">
      <div class="good-item cursor-pointer" v-for="(item,index) in goodList" :key="index" @click="handleSubmit(item.url, item.image)">
        <img class="mr24" :src="item.image" alt="">
        <div class="flex-1 flex-center flex-direction-column justify-content-space-between">
          <div class="ellipsis-1 good-title">{{item.title}}</div>
          <div class="w100p flex justify-content-space-between align-items-center mt20">
            <div class="fs32 opacity5 font-weight-600">{{item.price}}</div>
            <div class="commission">{{item.sale}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- bottom mask -->
    <div class="filter"></div>
    <!-- loading -->
    <Overlay :show="loading">
      <div class="flex-center flex-direction-column">
        <div v-if="loadingImgUrl"  class="img-wraper">
          <img :src="loadingImgUrl" width="250" style="border-radius: 20px;" alt="">
          <div class="line"></div>
        </div>
        <div style="width: 925px;" class="mt65">
          <el-progress :percentage="progress" :show-text="false" color="#6893FA" define-back-color="#666"></el-progress>
        </div>
        <div class="fs40 font-weight-600 mt30">正在解析商品信息...</div>
      </div>
    </Overlay>
  </div>
</template>

<script>
import axios from 'axios'
import { baseURL } from '../../../utils/config'

import Overlay from '../../../components/overlay.vue'
export default {
  name: "demo-page-2",
  components: {
    Overlay
  },
  data() {
    return {
      goodUrl: '',
      loading: false,
      progress: 0,
      interval: null,
      loadingImgUrl: '',
      goodList: [
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-1.png'),
          title:
            "家用草酸清洁剂卫生间浴室去污除水垢瓷砖家用草酸清洁剂卫生间浴室去污除水垢瓷砖",
          price: "¥9.9",
          sale: "佣金18%",
        },
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-2.png'),
          title:
            "山野妹妹手工腐竹原浆制作头层腐竹烧肉无盐无添加传统150g袋",
          price: "¥19.9",
          sale: "佣金20%",
        },
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-3.png'),
          title:
            "蜡象洗衣机槽清洁剂去污渍神器除霉活氧清蜡象洗衣机槽清洁剂去污渍神器除霉活氧清",
          price: "¥9.9",
          sale: "佣金24%",
        },
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-4.png'),
          title:
            "【洁尔阴】山茶花抑霉菌女士私处洗液 氨基酸山茶花抑霉菌女士私处洗液 氨基...",
          price: "¥39.9",
          sale: "佣金20%",
        },
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-5.png'),
          title:
            "【2盒仅6.9】蛋白棒牛油果可可布朗尼营牛油果可可布朗尼营",
          price: "¥6.9",
          sale: "佣金16%",
        },
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-6.png'),
          title:
            "大运河肥皂老牌子200g洗衣皂无磷加香去油河肥皂老牌子200g洗衣皂无",
          price: "¥7.9",
          sale: "佣金29%",
        },
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-7.png'),
          title:
            "美蒂芬家用洗衣液5斤装持久留香香氛去污美蒂芬家用洗衣液5斤装持久留香香氛去污...",
          price: "¥5.9",
          sale: "佣金4%",
        },
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-8.png'),
          title:
            "天水麻辣烫料包甘肃特产麻辣烫西北口味调天水麻辣烫料包甘肃特产麻辣烫西北口味调...",
          price: "¥12.8",
          sale: "佣金10%",
        },
        {
          url: 'https://v.douyin.com/iFAMbhgF/',
          image: require('@/assets/good/good-9.png'),
          title:
            "【第三盒仅需10元】舒子柒黄金浴香精油皂舒子柒黄金浴香精油皂子柒黄金浴",
          price: "¥29.9",
          sale: "佣金38%",
        },
      ],
    };
  },
  methods: {
    setProgress () {
      this.interval = setInterval(() => {
        if (this.progress < 95) {
          this.progress+= 2
        } else {
          clearInterval(this.interval)
        }
      }, 100)
    },

    async handleSubmit (url, imgUrl) {
      this.goodUrl = url ?? this.goodUrl
      this.loadingImgUrl = imgUrl
      if(!this.goodUrl){
        this.$message.warning("请输抖音商品链接！")
        return
      }
      try {
        this.loading = true
        this.setProgress()
        const {data: {data, code, msg}} = await axios.post(baseURL + '/v3/get_url_info', {
          url: this.goodUrl
        })
        if (code !== 0) {
          this.$message.warning(msg)
          return
        }
        const info = JSON.parse(data.information)
        let tempDesc = ''
        for (let [key, value] of Object.entries(info.desc.product_format)){
          tempDesc += `${key}：${value}；`
        }
        tempDesc += info.desc.ocr.join('、')
        let tempSellPoint = ''
        for (let [key, value] of Object.entries(info.sell_point)){
          tempSellPoint += `${key}：${value.join('、')}；`
        }
        const formData = {
          ...info,
          url: this.goodUrl,
          desc: tempDesc,
          sell_point: tempSellPoint
        }
        setTimeout(() => {
          this.progress = 100
          this.$emit('step', 'page3', {goodInfo: formData})
        }, 5000)
      } catch (e) {
        console.error(e)
      } finally {
        setTimeout(() => {
          this.progress = 0
          this.loading = false
          this.loadingImgUrl = ''
        }, 5000)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-2-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 75px;
  overflow: hidden;
  box-sizing: border-box;
  .search-input {
    height: 100px;
    width: auto;
    padding: 0px 120px 0px 75px;
    line-height: 100px;
    border-radius: 100px;
    background: #1b1b1b;
    box-sizing: border-box;
    border: 8px solid #7190f8;
    .search-icon {
      position: absolute;
      right: 30px;
      top: 20px;
      cursor: pointer;
    }
    input {
      height: 84px;
      width: 100%;
      padding: 0;
      font-size: 42px;
      color: #666666;
      line-height: 42px;
      background: #1b1b1b;
      border: none;
      outline: none;
    }
  }
  .title {
    color: #fff;
    margin-top: 60px;
  }
  .good-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 35px;
    .good-item {
      display: flex;
      color: #fff;
      border-radius: 20px;
      overflow: hidden;
      padding: 32px 30px;
      background: rgba($color: #D9D9D9, $alpha: 0.17);
      .good-title {
        font-size: 32px;
        font-weight: 600;
        opacity: 0.5;
      }
      .commission {
        font-size: 20px;
        font-weight: 600;
        padding: 4px 16px;
        border-radius: 10px;
        color: #FF8B7C;
        border: 1px solid #825555;
      }
      img {
        width: 186px;
        height: 186px;
        border-radius: 16px;
      }
    }
  }
  .filter {
    position: fixed;
    bottom: 0;
    width: calc(100% - 150px);
    height: 200px;
    background: linear-gradient(176.06deg, rgba(0, 0, 0, 0) 2.97%, #000000 96.54%);
  }
  .img-wraper {
    width: 250px;
    height: 250px;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    .line {
      width: 100%;
      height: 4px;
      background: #3EFFBA;
      position: absolute;
      top: 0;
      animation: sweep 4s linear infinite;
      // background-image: linear-gradient(81.02deg, rgb(250, 85, 96) -23.47%, rgb(177, 75, 244) 45.52%, rgb(77, 145, 255) 114.8%);
    }

    @keyframes sweep {
      0% { top: 0; }
      50% { top: calc(100% - 4px); }
      100% { top: 0; }
    }
  }
  &::v-deep {
    .el-progress-bar__inner {
      background-image: linear-gradient(81.02deg, rgb(250, 85, 96) -23.47%, rgb(177, 75, 244) 45.52%, rgb(77, 145, 255) 114.8%) !important;
    }
  }
}
</style>
