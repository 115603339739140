export const baseURL =
  "http://cmm-algo-digitalperson-live-streaming-backend-dev.cds8.cn";

export const flvURL = "http://127.0.0.1:8080/live/chanjing.flv";

export const keyURL = "http://127.0.0.1:8001/key";

export const remoteFlvURL = "http://47.103.119.215:8080/live/chanjing.flv";

export const remoteKeyURL = "http://47.103.119.215:8080/key";
