<template>
  <div class="w100p h100p" style="font-family: PingFang SC; overflow: hidden">
    <component
      :is="currentPage"
      v-bind="bindData"
      @step="handleStep"
    ></component>
  </div>
</template>

<script>
import WOW from "wow.js";
import "wow.js/css/libs/animate.css";
import Page1 from "./components/demo-page-1.vue";
import Page2 from "./components/demo-page-2_v2.vue";
import Page22 from "./components/demo-page-22.vue";
import Page3 from "./components/demo-page-3.vue";
import Page4 from "../live-page.vue";
import axios from "axios";
import { baseURL } from "@/utils/config";

export default {
  name: "config-page",
  data() {
    return {
      bindData: {
        channel: "",
        isAdmin: false,
        scriptData: {},
      },
      isCn: true,
      enScriptList: [],
      scriptList: [],
      session_id: "",
      currentPage: Page1,
    };
  },
  created() {
    // if (!this.$route.query.channel) {
    //   this.$router.replace({ query: {
    //     ...this.$route.query,
    //     channel: '99'
    //   }});
    // }
    this.bindData.channel = +this.$route.query.channel || 99;
    // 有效期24h
    this.bindData.isAdmin =
      Date.now() - parseInt(window.localStorage.getItem("is-admin") || 0) <
      1000 * 60 * 60 * 24;
  },
  mounted() {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
      callback: function (box) {},
      scrollContainer: null,
      resetAnimation: true,
    });
    wow.init();
  },
  methods: {
    async createSession() {
      const channel = this.bindData?.channel;
      let req =
        channel == 99
          ? {
              name: "头层腐竹",
              current_price: "69",
              desc: "产地：中国大陆；保质期：180天；净含量：150克；包装方式",
              num: 5,
              words_limit: 1,
              anchor_name: "小蝉蝉",
              selling_style: "通用风格",
              channel,
            }
          : {
              num: 5,
              words_limit: 1,
              anchor_name: "小蝉蝉",
              selling_style: "律所女",
              channel,
            };
      try {
        const resData = await axios.post(baseURL + "/v2/session", req);
        const res = resData.data;
        if (res.code !== 0) {
          this.$message.warning(res.msg);
          return;
        }
        this.session_id = res.data.session_id;
        this.scriptList = res.data.scripts?.map((v) => {
          return {
            text: v,
          };
        });
      } catch (e) {
        console.error(e);
      }
    },
    async handlePushFlow() {
      let text = [];
      if (this.isCn) {
        this.scriptList.forEach((v, i) => {
          console.log(v);
          text.push(v.text.replace(/\n{3,}/g, "\n\n"));
        });
      } else {
        this.enScriptList.forEach((v, i) => {
          text.push(v.text.replace(/\n{3,}/g, "\n\n"));
        });
      }
      const req = {
        session_id: this.session_id,
        channel: this.bindData.channel,
        lang: this.isCn ? "cn" : "en",
        text,
      };
      try {
        this.loading = true;
        const resData = await axios.post(baseURL + "/v3/create_wav", req);
        const res = resData.data;
        if (res.code !== 0) {
          this.$message.warning(res.msg);
          return;
        }
        const scriptData = {
          script: res.data,
          session_id: this.session_id,
        };
        // await this.getVideoProgress(scriptData);
        Object.assign(this.bindData, { scriptData });
        this.currentPage = Page4;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async getVideoProgress(scriptData) {
      while (this.bindData.channel == 100) {
        await new Promise((r) => setTimeout(r, 1000));
        try {
          const { channel, demo_id } = this.bindData;
          const url = baseURL + "/get_key";
          const resData = await axios.get(url, {
            params: { channel, demo_id },
          });
          const res = resData.data;
          const data = res?.data;
          // if (data?.key) {
          Object.assign(this.bindData, { scriptData });
          this.currentPage = Page4;
          break;
          // }
        } catch (e) {
          console.error(e);
        }
      }
    },
    async handleStep(step, data) {
      // if (['law-office-1', 'law-office-2'].includes(data?.demo_id)) {
      Object.assign(this.bindData, data);
      await this.createSession();
      await this.handlePushFlow();
      // return;
      // }
      // switch (step) {
      //   case 'page1':
      //     this.currentPage = Page1
      //     break
      //   case 'page2':
      //     this.currentPage = Page2
      //     break
      //   case 'page3':
      //     this.currentPage = Page22
      //     break
      //   case 'page4':
      //     this.currentPage = Page4
      //     break
      //   default:
      //     this.currentPage = Page1
      //     break
      // }
      // if (data) {
      //   Object.assign(this.bindData,data)
      // }
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background: #292929;
}
::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background: #5a5a5a;
  border-radius: 8px;
}
</style>
