<template>
  <div class="w100p h100p" style="font-family: PingFang SC;">
    <component
      :is="currentPage"
      v-bind="bindData"
      @step="handleStep"
    ></component>
  </div>
</template>

<script>
import Page1 from './components/demo-page-1.vue'
import Page2 from './components/demo-page-2.vue'
import Page3 from './components/demo-page-3.vue'
import Page4 from '../live-page.vue'

export default {
  name: 'config-page',
  data () {
   return {
    bindData: {},
    currentPage: Page1
   }
  },
  methods: {
    handleStep (step, data) {
      switch (step) {
        case 'page1':
          this.currentPage = Page1
          break
        case 'page2':
          this.currentPage = Page2
          break
        case 'page3':
          this.currentPage = Page3
          break
        case 'page4':
          this.currentPage = Page4
          break
        default:
          this.currentPage = Page1
          break
      }
      if (data) {
        Object.assign(this.bindData,data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
