import { render, staticRenderFns } from "./tab.vue?vue&type=template&id=db18b946&scoped=true"
var script = {}
import style0 from "./tab.vue?vue&type=style&index=0&id=db18b946&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db18b946",
  null
  
)

export default component.exports