<template>
  <div class="page-3-wrapper">
    <div :class="['left-panel', (loading || hasGenerateScript) && 'disabled']">
      <!-- 商品卡 -->
      <div class="good-card">
        <img src="https://cdn-static.chanmama.com/sub-module/static-file/b/f/9b8d932ee8?x-oss-process=image/resize,w_168" alt="">
        <div>{{ formData.name }}</div>
      </div>
      <!-- 表单信息 -->
      <div class="form-wrapper">
        <el-form ref="form" :model="formData" label-width="120px">
          <el-form-item label="商品描述">
            <el-input v-model="formData.desc" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" placeholder="请输入商品描述"></el-input>
          </el-form-item>
          <el-form-item label="卖点信息">
            <el-input v-model="formData.sell_point" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入商品卖点信息"></el-input>
          </el-form-item>
          <div class="form-item-row">
            <el-form-item class="flex-1" label="原始价格">
              <el-input v-model="formData.original_price" placeholder="请输入商品原始价格"></el-input>
            </el-form-item>
            <el-form-item class="flex-1 ml60" label="当前价格">
              <el-input v-model="formData.current_price" placeholder="请输入商品当前价格"></el-input>
            </el-form-item>
          </div>
          <div class="form-item-row">
            <el-form-item class="flex-1" label="折扣信息">
              <el-input v-model="formData.special_offers" placeholder="请输入商品折扣信息"></el-input>
            </el-form-item>
            <el-form-item class="flex-1 ml60" label="发货地">
              <el-input v-model="formData.shipments_province" placeholder="请输入发货地"></el-input>
            </el-form-item>
          </div>
          <el-form-item class="flex-1" label="小店名称">
              <el-input v-model="formData.shop_name" placeholder="请输入小店名称"></el-input>
          </el-form-item>
          <el-form-item class="flex-1" label="品牌名称">
            <el-input v-model="formData.brand_name" placeholder="请输入品牌名称"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="right-panel ml10">
      <div class="script-container" v-if="hasGenerateScript">
        <div class="flex-1 flex flex-direction-column" style="overflow: hidden;">
          <div class="w100p flex justify-content-space-between align-items-center">
            <div class="fs32 font-weight-600">带货文案</div>
            <!-- <div class="fs24" style="color: #FF4A4A;">该段文案存在违禁词或敏感词，请检查修改</div> -->
          </div>
          <div class="divede-line mt24 mb10"></div>
          <div class="flex-1 no-scrollbar" style="overflow: auto;">
            <div class="mb40" v-for="(v, i) in scriptList" :key="i">
              <MyTextarea ref="MyTextarea" :text="v.text" :currentIndex="i"></MyTextarea>
            </div>
          </div>
        </div>
        <div>
          <div class="divede-line mb80 mt10"></div>
          <div class="fs32 font-weight-600">主播设置</div>
          <div class="flex justify-content-space-between mt40" style="width: 100%;">
            <SelectWithTitle class="flex-1" title="名称" :option="nameOption" :showArrow="false" v-model="formData.anchor_name"></SelectWithTitle>
            <div style="width: 20px"></div>
            <SelectWithTitle class="flex-1" title="风格" :option="styleOption" v-model="formData.selling_style"></SelectWithTitle>
          </div>
          <div class="button flex-center" @click="handlePushFlow">
            <img v-if="loading" class="mr20 z100" src="https://cdn-static.chanmama.com/res/upload/2024/03/12/rolling-icon.svg" width="50" height="50" alt="">
            <span class="z100">开启直播推流</span>
            <div class="blur"></div>
          </div>
        </div>
      </div>
      <div v-else class="flex-center flex-direction-column h100p">
        <div :class="['flex', 'justify-content-space-between', loading && 'disabled']" style="width: 100%">
          <SelectWithTitle class="flex-1" title="文案段落" :option="numOption" v-model="formData.num"></SelectWithTitle>
          <div style="width: 20px"></div>
          <SelectWithTitle class="flex-1" title="每段文字" :option="wordOption" v-model="formData.words_limit"></SelectWithTitle>
        </div>
        <div class="button flex-center" @click="createSession">
          <img v-if="loading" class="mr20 z100" src="https://cdn-static.chanmama.com/res/upload/2024/03/12/rolling-icon.svg" width="50" height="50" alt="">
          <span class="z100">生成带货文案</span>
          <div class="blur"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {baseURL} from '@/utils/config'

import MyTextarea from '@/components/MyTextarea.vue'
import SelectWithTitle from '@/components/select-with-title.vue'
export default {
  props: {
    goodInfo: {
      type: Object,
      default: () => {}
    },
    channel: {}
  },
  components: {
    MyTextarea,
    SelectWithTitle
  },
  data() {
    return {
      isCn: true,
      loading: false,
      session_id: null,
      scriptList: [],
      destroied: false,
      hasGenerateScript: false,
      formData: {
        ...this.goodInfo,
        num: 5,
        words_limit: 1,
        anchor_name: "小蝉蝉",
        selling_style: '通用风格',
      },
      numOption: [{
        label: '1段',
        value: 1
      },{
        label: '2段',
        value: 2
      },{
        label: '3段',
        value: 3
      },{
        label: '4段',
        value: 4
      },{
        label: '5段',
        value: 5
      },],
      wordOption: [{
        label: '5000字以内',
        value: 1
      },{
        label: '5000-10000字',
        value: 2
      },],
      nameOption: [
      {
        label: '小蝉蝉',
        value: '小蝉蝉'
      }
      ],
      styleOption: [
      {
        label: '通用风格',
        value: '通用风格'
      },{
        label: '李佳琦',
        value: '李佳琦',
      },{
        label: '杨雨盈',
        value: '杨雨盈',
      }
      ]
    }
  },
  beforeDestroy() {
    this.destroied = true
  },
  methods: {
    async createSession() {
      if (this.loading) {
        return
      }
      const judgeList = [
        {condition: this.formData.name, msg: '商品名称不可为空'},
        {condition: this.formData.current_price, msg: '当前价格不可为空'},
        {condition: parseInt(this.formData.num) > 0, msg: '文案数量必须要大于0'}
      ]
      const result = judgeList.find(item => !item.condition)
      if(result){
        this.$message.warning(result.msg)
        return
      }
      let req = {
        ...this.formData,
        "num": parseInt(this.formData.num),
        channel: this.channel
      };
      try {
        this.loading = true
        const resData = await axios.post(baseURL + '/v2/session', req)
        const res = resData.data
        if (res.code !== 0) {
          this.$message.warning(res.msg)
          return;
        }
        this.session_id = res.data.session_id;
        this.scriptList = res.data.scripts?.map(v => {
          return {
            text: v
          }
        })
        setTimeout(() => {
          this.loading = false
          this.hasGenerateScript = true
        }, 4500)
      } catch (e) {
        console.error(e)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 4500)
      }
    },
    async handlePushFlow () {
      if (!this.session_id) {
        this.$message.warning('请先生成带货脚本')
        return
      }
      let text = ''
      if(this.isCn){
        this.scriptList.forEach((v, i) => {
          const tempStr = this.$refs.MyTextarea[i]?.getText()
          v.text = tempStr.replace(/\n{3,}/g, "\n\n")
        })
        text = this.scriptList.map(v => {
          return v.text
        })
      }else{
        this.enScriptList.forEach((v, i) => {
          const tempStr = this.$refs.MyTextarea[i]?.getText()
          v.text = tempStr.replace(/\n{3,}/g, "\n\n")
        })
        text = this.enScriptList.map(v => {
          return v.text
        })
      }
      const req = {
        session_id: this.session_id,
        channel: this.channel,
        lang: this.isCn ? 'cn' : 'en',
        text
      }
      try {
        this.loading = true
        const resData = await axios.post(baseURL + '/v3/create_wav', req)
        const res = resData.data
        if (res.code !== 0) {
          this.hasGenerateScript = false
          this.$message.warning(res.msg)
          return;
        }
        const scriptData = {
          script: res.data,
          session_id: this.session_id
        }
        await this.getVideoProgress(scriptData)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async getVideoProgress(scriptData) {
      while (true) {
        await new Promise(r => setTimeout(r, 1000))
        try {
          const url = baseURL + '/get_key'
          const resData = await axios.get(url + '?channel=' + this.channel)
          const res = resData.data
          const data = res?.data
          if (data?.key) {
            this.$emit('step', 'page4', {scriptData});
            break
          }
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-3-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 40px;
  overflow: hidden;
  box-sizing: border-box;
  .left-panel {
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px;
    border-radius: 20px;
    box-sizing: border-box;
    background: rgba($color: #D9D9D9, $alpha: 0.1);
    .good-card {
      display: flex;
      align-items: center;
      padding: 15px;
      border-radius: 20px;
      background: #000;
      img {
        width: 84px;
        height: 84px;
        border-radius: 10px;
      }
      div {
        font-size: 24px;
        font-weight: 600;
        margin-left: 30px;
        color: rgba($color: #FFF, $alpha: 0.6);
      }
    }
    .form-wrapper {
      margin-top: 36px;
      overflow: auto;
      scrollbar-width: none;
      ::-webkit-scrollbar {
          display: none;
      }
      .mt23 {
        margin-top: 23px;
      }
      .form-item-row {
        width: 100%;
        display: flex;
        &::v-deep {
          .el-form-item__label {
              margin-top: 23px;
            }
        }
      }
      &::v-deep {
        .add-goods-input{
          width: 707px;
          margin-top: 200px;
          .el-input__inner{
            border-radius: 12px;
            height: 56px;
            border: 1px solid rgba(255, 255, 255, 0.6);
          }
        }
        .question-btn-box .el-input__inner{
          border: none;
        }
        .el-form-item {
          margin-bottom: 40px;
          font-family: PingFang SC;
          &:last-child {
            margin-bottom: 0
          }
          &:nth-child(n+3) {
            .el-form-item__label {
              margin-top: 23px;
            }
          }
        }
        .el-form-item__label {
          color: #999;
          font-weight: 600;
          font-size: 24px;
          line-height: 34px;
        }
        .el-input__inner {
          height: 80px;
          padding: 22px;
          font-size: 24px;
          background-color: transparent;
          border: 1px solid #4a4a4a;
          color: #FFF;
          font-family: PingFang SC;
        }
        .el-input__inner::placeholder {
          color: #666;
        }
        .el-input-group__append{
          padding-top: 3px;
          width: 33px;
          background: transparent;
          border: 1px solid #4a4a4a;
          border-left: none;
          transform: translateX(-1px);
        }
        .el-textarea__inner{
          min-height: 42px !important;
          padding: 24px;
          font-size: 24px;
          background-color: transparent;
          border: 1px solid #4a4a4a;
          color: #CBCBCB;
          font-family: PingFang SC;
        }
        .el-textarea__inner::placeholder {
          color: #666;
        }
        .el-textarea__inner::-webkit-scrollbar {
          width: 8px;
        }
        .el-textarea__inner::-webkit-scrollbar-track {
          background: transparent;
        }
        .el-textarea__inner::-webkit-scrollbar-thumb {
          background: #4a4a4a;
          border-radius: 8px;
        }
        .el-textarea__inner::-webkit-scrollbar-thumb:hover {
          background: #5a5a5a;
          border-radius: 8px;
        }
      }
    }
  }
  .right-panel {
    flex: 1;
    color: #FFF;
    .button {
      width: 100%;
      height: 106px;
      margin-top: 50px;
      font-size: 36px;
      color: #FFF;
      font-weight: 600;
      cursor: pointer;
      position: relative;
      border-radius: 20px;
      background: linear-gradient(91.68deg, #5A97FE 6.58%, #DB73DD 99.57%);
      .blur {
        position: absolute;
        bottom: -20%;
        width: 80%;
        height: 80%;
        filter: blur(40px);
        opacity: 0.5;
        background: linear-gradient(164.22deg, #5398FF 11.02%, #FF69D5 95.21%);
      }
      .z100 {
        z-index: 100;
      }
    }
    .script-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 40px 0 80px;
      .divede-line {
        width: 100%;
        height: 1px;
        background: rgba($color: #FFF, $alpha: 0.4);
      }
      .no-scrollbar::-webkit-scrollbar {
        width: 4px;
      }
      .no-scrollbar::-webkit-scrollbar-track {
        background: transparent;
      }
      .no-scrollbar::-webkit-scrollbar-thumb {
        background: #FFF;
        border-radius: 10px;
      }
      .no-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #FFF;
        border-radius: 10px;
      }
    }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
