<template>
  <div class="box" v-if="text">
    <div class="title_box">
      <span class="cfff font-weight-600 fs16">文案{{ currentIndex + 1 }}:</span>
      <div v-if="isShowTip" class="fs14" style="color: #ff6565;">该段文案存在违禁词或敏感词，请检查修改</div>
    </div>
    <div class="content" ref="content" :contenteditable="true" v-html="content">
    </div>
  </div>
</template>

<script>
export default {
  props: ['currentIndex', 'text',],
  data () {
    return {
      content: '',
      isShowTip: false
    }
  },
  mounted() {
    // this.content = this.text
    // this.isShowTip = this.content.includes('</span>')
  },
  watch: {
    text: {
      handler(val){
        this.content = val
        this.isShowTip = this.content.includes('</span>')
      },
      immediate: true
    }
  },
  methods: {
    getText () {
      return this.$refs.content.innerText
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  position: relative;


  .title_box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .content {
    background: transparent;
    padding: 63px 20px 10px;
    font-size: 14px;
    color: #cbcbcb;
    white-space: pre-line;
  }

  [contenteditable]:focus {
    outline: none;
  }
}
</style>
